<template>
  <div @click.self="clickOnOverlay" v-show="dialogVisible" class="bn-overlay">
    <div
      class="bn-dialog"
      :style="{
        left: modalCoords ? `${modalCoords.coordX}px` : 'unset',
        top: modalCoords ? `${modalCoords.coordY}px` : '50%',
        transform: modalCoords ? 'translateY(0%)' : 'translateY(-50%)',
      }"
    >
      <div v-if="showHeader" class="bn-dialog__header">
        <slot name="header">
          <h3 class="bn-dialog__title">{{ title }}</h3>
          <button
            @click="close"
            type="button"
            class="bn-dialog__header-close btn"
          >
            <bn-icon icon="close" />
          </button>
        </slot>
      </div>
      <div class="bn-dialog__body">
        <slot></slot>
      </div>
      <div v-if="showFooter" class="bn-dialog__footer">
        <slot name="footer" :close="close" :confirm="confirm">
          <bn-button color="success" @click="confirm">Ок</bn-button>
          <bn-button color="danger" @click="close">Отмена</bn-button>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BnModal',
  props: {
    showHeader: { type: Boolean, required: false, default: true },
    showFooter: { type: Boolean, required: false, default: true },
    clickOutside: { type: Boolean, required: false, default: true },
    modalCoords: { type: Object, required: false },
    title: { type: String, required: false },
  },

  currentPopupController: null,

  data() {
    return {
      dialogVisible: false,
    };
  },

  mounted() {
    document.addEventListener('keydown', this.handleKeydown);
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.handleKeydown);
  },

  methods: {
    handleKeydown(e) {
      if (this.dialogVisible && e.key === 'Escape') {
        this.close();
      }
    },

    open() {
      let resolve;
      let reject;
      const modalPromise = new Promise((ok, fail) => {
        resolve = ok;
        reject = fail;
      });

      this.$options.currentPopupController = { resolve, reject };
      this.dialogVisible = true;

      return modalPromise;
    },

    confirm() {
      this.$options.currentPopupController.resolve(true);
      this.dialogVisible = false;
    },

    clickOnOverlay() {
      if (!this.clickOutside) return;
      this.close();
    },

    close() {
      this.$options.currentPopupController.resolve(false);
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss">
.bn-dialog {
  display: flex;
  flex-direction: column;
  position: absolute;
  max-height: 98vh;
  max-width: 100%;
  overflow: hidden;
  background-color: #fff;
  border-radius: $border-radius;

  &__header {
    position: relative;
    padding: $padding-primary;
    padding-right: 30px;

    &-close {
      position: absolute;
      z-index: 100;
      background-color: transparent;
      border: 0;
      padding: 0;
      display: flex;
      top: $padding-primary;
      right: $padding-primary;
      cursor: pointer;

      svg {
        width: 24px;
        height: 24px;
        fill: lighten($color-primary, 30%);
      }
    }
  }
  &__title {
    padding: 0;
    margin: 0;
    color: $color-primary;
  }
  &__body {
    flex: 1;
    padding: $padding-primary;
    overflow: hidden;
    overflow-y: auto;
  }
  &__footer {
    padding: 0 $padding-primary $padding-primary;

    & :first-child {
      margin-right: 4%;
    }
    button {
      width: 48%;
    }
  }
}
</style>
