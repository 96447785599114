<template>
  <div class="form-auth">
    <div class="form-auth__header">
      <img src="@/assets/img/logo-d.svg" alt="" />
    </div>
    <div class="form-auth__body">
      <p v-if="!isLoggedIn">Для продолжения необходимо авторизоваться</p>
      <p v-else>Выход из системы</p>
    </div>
    <div class="form-auth__footer">
      <bn-button
        v-if="!isLoggedIn"
        color="success"
        type="submit"
        @click="$emit('to-auth-service')"
        >Войти</bn-button
      >
      <bn-button v-else color="success" type="warning" @click="$emit('logout')"
        >Выход</bn-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthForm',
  data() {
    return {};
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
.form-auth {
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    img {
      width: 140px;
    }
  }
  &__body {
    max-width: 260px;
    margin-bottom: 20px;
  }
  &__footer {
    button {
      width: 100%;
      margin-bottom: 15px;
    }
  }
}
</style>
