<template>
  <section class="create-stmt__page flex flex-col">
    <div class="form-group flex-1">
      <!-- 
      <bn-input
        @input-changed="updateInput('number', $event)"
        label="Номер заявки на банкон24"
        :hasError="formData.number.hasError"
      /> -->

      <div class="flex">
        <bn-input :hasError="formData.paid_dt.hasError" label="Дата оплаты">
          <bn-datepicker
            @selected="
              updateInput({ name: 'paid_dt', value: formattedDate($event) })
            "
          />
        </bn-input>

        <bn-input :hasError="formData.bank.hasError" label="Партнер (Банк)">
          <bn-select
            :list="banksDictionary"
            :search="true"
            @clear="updateInput({ name: 'bank', value: null })"
            @selected="updateInput({ name: 'bank', value: $event })"
        /></bn-input>
      </div>

      <div class="flex">
        <bn-input
          :hasError="formData.product.hasError"
          label="Продукт"
          class="mr-2"
        >
          <bn-select
            :list="productsList"
            @clear="updateInput({ name: 'product', value: null })"
            @selected="updateInput({ name: 'product', value: $event })"
          />
        </bn-input>

        <bn-input
          :hasError="formData.product_type.hasError"
          label="Тип"
          class="mr-2"
        >
          <bn-select
            :list="typeList"
            @clear="updateInput({ name: 'product_type', value: null })"
            @selected="updateInput({ name: 'product_type', value: $event })"
          />
        </bn-input>

        <bn-input :hasError="formData.product_type.hasError" label="Закон">
          <bn-select
            :list="lawsDictionary"
            @clear="updateInput({ name: 'law', value: null })"
            @selected="updateInput({ name: 'law', value: $event })"
          />
        </bn-input>
      </div>

      <bn-input
        label="Номер аукциона"
        :hasError="formData.auction_number.hasError"
        @input-changed="updateInput({ name: 'auction_number', value: $event })"
      />
    </div>
  </section>
</template>

<script>
import BnDatepicker from '../ui/BnInputDatepicker.vue';
import { formattedDate } from '../../helpers/index';

export default {
  props: {
    formData: { type: Object, required: false, default: () => {} },
  },
  emits: ['update-form'],
  components: {
    BnDatepicker,
  },

  data() {
    return {
      typeList: null,
    };
  },
  computed: {
    banksDictionary() {
      return this.$store.state.dictionaries.banks;
    },
    lawsDictionary() {
      return this.$store.state.dictionaries.laws;
    },
    productsList() {
      const list = {};

      if (!this.productsDictionary) return list;

      Object.keys(this.productsDictionary).forEach(key => {
        list[key] = this.productsDictionary[key].name;
      });

      return list;
    },
    productsDictionary() {
      return this.$store.state.dictionaries.products;
    },
  },
  watch: {
    'formData.product.value'(newValue) {
      if (!newValue) return;

      const key = Object.keys(newValue)[0];

      this.typeList = this.productsDictionary[key].types;
    },
  },
  methods: {
    valueFromObj(obj = {}) {
      if (!obj) return;

      return Object.values(obj)[0];
    },
    updateInput(data) {
      this.$emit('update-form', data);
    },
    formattedDate,
  },
};
</script>

<style></style>
