<template>
  <div class="bn-table__change">
    <div v-if="inputType === 'datepicker'">
      <el-config-provider :locale="locale">
        <datepicker
          ref="input"
          v-model="inputValue"
          :disabled-date="disabledDate"
        />
      </el-config-provider>
    </div>
    <input v-else ref="input" v-model.lazy="inputValue" v-money="money" />

    <bn-button
      @click="$emit('confirm', { key: stmtKey, value: inputValue })"
      color="success"
      :disabled="!inputValue || !inputValueChanged"
    >
      <bn-icon icon="check" />
    </bn-button>
    <bn-button color="danger" icon="close" @click="close" />
  </div>
</template>

<script>
import ru from 'element-plus/lib/locale/lang/ru';
import { ElConfigProvider } from 'element-plus';
import { toCurrency, toNumber } from '../../helpers/index';

export default {
  components: {
    ElConfigProvider,
  },
  props: {
    inputType: { type: String, required: false },
    stmtValue: { type: [Number, String], required: false },
    stmtKey: { type: String, required: false },
  },

  data() {
    return {
      locale: ru,
      inputValue: null,
      inputValueChanged: false,
      money: { precision: 2, decimal: ',', thousands: ' ' },
    };
  },
  methods: {
    close() {
      this.inputValue = null;
      this.inputValueChanged = false;
      this.$emit('close');
    },
    disabledDate(time) {
      return time.getTime() > Date.now();
    },
    toCurrency,
  },
  watch: {
    stmtValue(value) {
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
      this.inputType === 'datepicker'
        ? (this.inputValue = new Date(value))
        : (this.inputValue = this.toCurrency(value));
    },
    inputValue() {
      if (this.stmtValue === null || this.inputValue === null) {
        this.inputValueChanged = false;
        return;
      }

      if (this.inputType === 'datepicker') {
        const oldValue = new Date(this.stmtValue).getTime();
        const newValue = new Date(this.inputValue).getTime();

        this.inputValueChanged = oldValue !== newValue;

        return;
      }

      this.inputValueChanged = toNumber(this.inputValue) !== this.stmtValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.bn-table__change {
  display: flex;
  justify-content: center;
  z-index: 20;

  button {
    margin-left: 10px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}
input:focus {
  border: 1px solid $color-success;
}
</style>
