import { fetchData } from './fetch';

const base = process.env.VUE_APP_API_URL;

// lifetime - время жизни словаря в мин.
const getDictionaries = async (lifetime = 30) => {
  const savedDictionaries = JSON.parse(sessionStorage.getItem('dictionaries'));
  const dictionariesExpired = Number(
    sessionStorage.getItem('dictionariesExpired'),
  );
  const isExpired = dictionariesExpired < new Date().getTime();

  if (savedDictionaries && !isExpired) {
    return { ok: true, dictionaries: savedDictionaries };
  }

  try {
    const { ok, status, response } = await fetchData(`${base}/dictionaries`);
    const dictionaries = response?.data;

    if (!ok && !dictionaries) throw { status, response };

    sessionStorage.setItem('dictionaries', JSON.stringify(dictionaries));
    sessionStorage.setItem(
      'dictionariesExpired',
      new Date().getTime() + lifetime * 60 * 1000,
    );

    return { ok: true, dictionaries };
  } catch (error) {
    console.error('Ошибка при получении списка банков', error);

    return {
      ok: false,
      status: error?.status,
      response: error?.response,
      msg: 'Ошибка при получении списка банков',
      error,
    };
  }
};
const getDataByInn = async inn => {
  try {
    const { ok, status, response } = await fetchData({
      url: `${process.env.VUE_APP_FEEDBACK_API_URL}/api/v1/organization?inn=${inn}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: process.env.VUE_APP_FEEDBACK_API_TOKEN,
      },
    });
    if (!ok) throw { status, response };

    return { ok, status, response };
  } catch (error) {
    console.error('Ошибка при проверке ИНН');
  }
};

const getDadataInfo = async query => {
  try {
    const { ok, status, response } = await fetchData({
      url: process.env.VUE_APP_DADATA_API_URL,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Token ' + process.env.VUE_APP_DADATA_API_TOKEN,
      },
      data: { query: query },
    });
    console.log(ok, status, response);
    if (!ok) throw { status, response };

    return { ok, status, response };
  } catch (error) {
    console.error('Ошибка при запросе данных');
  }
};

export { getDictionaries, getDataByInn, getDadataInfo };
