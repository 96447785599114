<template>
  <div class="bn-input" :class="{ 'has-error': hasError }">
    <span v-if="label" class="bn-input__label">{{ label }}</span>
    <slot>
      <input
        v-if="mask === 'inn'"
        v-model="input"
        v-mask="'############'"
        :disabled="disabled"
        type="text"
        class="bn-input__inner"
      />
      <input
        v-else-if="mask === 'money'"
        v-model="input"
        v-money="money"
        :disabled="disabled"
        type="text"
        class="bn-input__inner"
      />
      <input
        v-else
        type="text"
        v-model="input"
        :disabled="disabled"
        class="bn-input__inner"
      />
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: String, required: false, default: null },
    disabled: { type: Boolean, required: false, default: false },
    label: { type: String, required: false, default: null },
    mask: { type: String, required: false, default: null },
    hasError: { type: Boolean, required: false, default: false },
  },
  emits: ['update:value', 'input-changed'],
  data() {
    return {
      input: '',
      money: { precision: 2, decimal: ',', thousands: ' ' },
    };
  },
  mounted() {
    if (this.value) this.input = this.value;
  },
  watch: {
    input(value) {
      this.$emit('input-changed', value);
      this.$emit('update:value', value);
    },
  },
};
</script>

<style lang="scss">
.bn-input {
  width: 100%;

  &__inner {
    width: 100%;
    text-overflow: ellipsis;
  }
  &__label {
    color: #757575;
    display: block;
    margin-bottom: 3px;
  }
  &.has-error {
    input {
      border: 1px solid $color-danger;
    }
  }
}
</style>
