<template>
  <div class="bn-button-group-wrap">
    <bn-dropdown-menu @close="isOpen = false" :is-open="isOpen">
      <div class="bn-button-group">
        <slot></slot>
        <bn-button
          @click="isOpen = true"
          color="success"
          icon="chevron-down"
          class="bn-dropdown__caret-button"
        />
      </div>
      <template #dropdown v-if="isOpen"><slot name="dropdown"></slot></template>
    </bn-dropdown-menu>
  </div>
</template>

<script>
export default {
  data() {
    return { isOpen: false };
  },
};
</script>
<style lang="scss">
.bn-button-group {
  display: flex;

  & > .bn-button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  & > .bn-button:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  & .bn-button {
    margin: 0;
    position: relative;
  }
}
</style>
