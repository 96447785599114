<template>
  <th
    @click="clickHandler"
    @contextmenu.prevent="openContextMenu"
    colspan="1"
    rowspan="1"
    class="n-table__cell"
    :class="[
      column.sortable ? 'n-table__cell--sortable' : '',
      column.filterable ? 'n-table__cell--filterable' : '',
      sortType,
    ]"
    :data-column-name="column.name"
  >
    <div class="cell">
      <span>
        <slot> {{ column.title }}</slot>
      </span>
    </div>
    <span v-if="column.sortable" class="caret-wrapper">
      <bn-icon :size="16" class="sort-caret ascending" icon="menu-up" />
      <bn-icon :size="16" class="sort-caret descending" icon="menu-down" />
    </span>
    <span
      v-if="column.filterable"
      class="filter-status"
      :class="{
        'filter-status--active': filterApplied,
      }"
    >
      <bn-icon :size="14" class="filter-status__icon" icon="filter" />
    </span>
  </th>
</template>

<script>
export default {
  props: {
    sorting: {
      type: Object,
      required: false,
      default: () => {
        return { name: null, type: '' };
      },
    },
    column: { type: Object, required: false, default: () => {} },
    appliedFilters: { type: Array, required: false, default: () => [] },
  },
  computed: {
    sortType() {
      if (this.column.name !== this.sorting.name) return '';
      return this.sorting.type === '1'
        ? 'asc'
        : this.sorting.type === '-1'
        ? 'desc'
        : '';
    },
    filterApplied() {
      return this.appliedFilters.find(item => item === this.column.name);
    },
  },
  watch: {
    sorting: {
      deep: true,
      handler: function () {
        this.$emit('is-sorted', this.sorting);
      },
    },
  },
  methods: {
    clickHandler() {
      if (!this.column.sortable) return;

      const type =
        this.sorting.type === '' ? '1' : this.sorting.type === '1' ? '-1' : '';

      this.$emit('is-sorted', {
        name: this.column.name,
        type,
      });
    },
    openContextMenu(e) {
      if (!this.column.filterable) return;

      const currentCol = e.target.closest('.n-table__cell');

      this.$emit('open-menu', {
        name: this.column.name,
        coordX: currentCol.getBoundingClientRect().left,
        coordY: currentCol.getBoundingClientRect().bottom,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.n-table {
  th.n-table__cell {
    padding: 15px 0;
    border-right: 1px solid #cacaca;
    border-bottom: 1px solid transparent;
    background-color: #eee;
  }
  &__cell {
    &--sortable {
      cursor: pointer;
    }
    &.desc {
      .ascending {
        fill: #b9b9b9;
      }
      .descending {
        fill: #409eff;
      }
    }
    &.asc {
      .descending {
        fill: #b9b9b9;
      }
      .ascending {
        fill: #409eff;
      }
    }
  }
}
.caret-wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  vertical-align: middle;
  cursor: pointer;
  overflow: initial;
  position: absolute;
  right: 2px;
  top: 50%;
  transform: translateY(-50%);

  .sort-caret {
    fill: #919191;
  }
}

.filter-status {
  position: absolute;
  left: 4px;
  bottom: 0px;

  &__icon {
    fill: #999999;
  }
  &--active &__icon {
    fill: $color-warning;
  }
}
</style>
