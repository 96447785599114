import Logger from './logger';

/**
 * DsService основной класс работы с ЭЦП
 * для работы необходимо КриптоПро API https://www.cryptopro.ru/sites/default/files/products/cades/cadesplugin_api.js
 * Основные методы класса
 * init() Проверяем доступность плагина при успехе pluginReady = true
 * getCertificates() Получаем массив действующих сертификатов
 * signing(certificate, data) Подписываем данные, поле original - обязательно только для соподписи отсоединенной подписи. Возвращает результат подписания
 * verify(sign) Проверка валидности подписи, возвращает массив [{cert-сертификат, ts-время подписания}] иначе false
 */
export default class DsService {
  CAPICOM_ENCODE_BASE64 = 0; // формат экспорта сертификата
  pluginReady = false;

  constructor() {
    window.logger = new Logger(); // Инициализация записи логов
  }

  /**
   * Обрабатываем событие инициализации плагина
   * @returns {Promise}
   */
  async init() {
    try {
      await cadesplugin;

      const checked = await this._check();

      if (!checked) {
        logger.add('КриптоПро ЭЦП Browser plug-in не активирован', true);
        throw 'КриптоПро ЭЦП Browser plug-in не активирован';
      }

      logger.add('КриптоПро ЭЦП Browser plug-in готов');

      return { ok: true };
    } catch (error) {
      logger.add(error, true);

      return { ok: false, error };
    }
  }

  /**
   * Получаем валидные сертификаты из store
   * @param {Number} location Расположение хранилища сертификатов.
   * По умолчанию: CAPICOM_CURRENT_USER_STORE = 2	Хранилище текущего пользователя.
   * @param {String} storeName Строка с именем открываемого системного хранилища сертификатов.
   * По умолчанию: CAPICOM_MY_STORE = "My"	Хранилище персональных сертификатов пользователя.
   * @param {Number} mode режим открытия хранилища.
   * По умолчанию: CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED = 2
   * Открывает хранилище на чтение/запись, если пользователь имеет права на чтение/запись.
   * Если прав на запись нет, то хранилище открывается на чтение.
   * @returns {Promise<Array>} Массив с объектами подписей
   */
  async getCertificates(location, storeName, mode) {
    const _location = location || cadesplugin.CAPICOM_CURRENT_USER_STORE;
    const _storeName = storeName || cadesplugin.CAPICOM_MY_STORE;
    const _mode = mode || cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED;

    logger.add('Получаем список сертификатов');

    try {
      const store = await this._createStore();
      store.Open(_location, _storeName, _mode);

      const certsList = await store.Certificates;
      const validCerts = await certsList.Find(
        cadesplugin.CAPICOM_CERTIFICATE_FIND_TIME_VALID,
      );
      const count = await validCerts.Count;

      const certificates = [];

      for (let i = 1; i <= count; i++) {
        const cert = await validCerts.Item(i);
        certificates.push(await this.parseCertificate(cert));
      }

      store.Close();
      if (certificates.length === 0) {
        logger.add('Нет действующих сертификатов для подписи', true);
        throw 'Нет действующих сертификатов для подписи';
      }
      logger.add({
        msg: 'Cписок сертификатов успешно получен',
        certificates: certificates,
      });
      return { ok: true, certificates };
    } catch (error) {
      logger.add(
        {
          msg: 'Ошибка при получении списка сертификатов',
          err: error,
        },
        true,
      );
      return { ok: false, error };
    }
  }

  /**
   * Подписываем данные выбранным сертификатом
   * @param {Object} certificate Объект выбранного сертификата
   * @param {Object} data Объект содержащий данные на подпись и параметры
   * Пример: { file: dataInBase64, id: 6570, isCoSign: false, isDetached: false}
   * @returns {Promise<Object>} Возвращает объект с подписанными данными
   */
  sign(certificate, data) {
    if (
      !data?.file?.content ||
      typeof data?.file?.content !== 'string' ||
      data?.file?.content === ''
    ) {
      logger.add('Неправильный формат данных на подпись', true);
      return;
    }

    return this._signString(certificate, data);
  }

  /**
   * Подписываем строку выбранным сертификатом
   * @param {Object} certificate Объект выбранного сертификата
   * @param {Object} data Объект содержащий данные на подпись и параметры
   * @returns {Promise<Object>} Возвращает объект с подписанными данными
   */
  async _signString(certificate, data) {
    const isCoSign = data.isCoSign || false;
    const isDetached = data.isDetached || false;
    const dataTosign = data.file.content;

    let propsetContent = dataTosign;
    let signedMessage;
    let verifyResult;

    if (isDetached && isCoSign) {
      // Для соподписания открепленной подписью необходимо передать исходный подписываемый файл
      if (!data.file.original || data.file.original === '') {
        logger.add(
          '[Соподписание] Отсутствует исходный подписываемый файл',
          true,
        );
        throw '[Соподписание] Отсутствует исходный подписываемый файл';
      }
      propsetContent = data.file.original;
    }

    try {
      logger.add({
        msg: 'Начинаем подписание',
        isDetached: isDetached,
        isCoSign: isCoSign,
        dataTosign: dataTosign,
        propsetContent: propsetContent,
        certificate: certificate,
      });

      const signer = await this._createSigner();
      const signedData = await this._createSignedData();

      await signer.propset_Certificate(certificate.$original || certificate);
      await signer.propset_Options(
        cadesplugin.CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN,
      );
      await signedData.propset_ContentEncoding(
        cadesplugin.CADESCOM_BASE64_TO_BINARY,
      );
      await signedData.propset_Content(propsetContent);

      if (isCoSign) {
        logger.add('[Соподписание] Идёт проверка имеющихся подписей');
        await signedData.VerifyCades(
          dataTosign,
          cadesplugin.CADESCOM_CADES_BES,
          isDetached,
        );
        logger.add({
          msg: '[Соподписание] Подписи проверены',
          res: await this._signInfo(signedData),
        });
        logger.add('[Соподписание] Подписываем данные');
        // https://docs.cryptopro.ru/cades/reference/cadescom/cadescom_interface/icpsigneddata2signcades
        signedMessage = await signedData.CoSignCades(
          signer,
          cadesplugin.CADESCOM_CADES_BES,
        );
      } else {
        // SignCades (Signer, CadesType, bDetached, EncodingType)
        // Signer Объект CPSigner или CAPICOM.Signer, который будет использован для создания подписи.
        // CadesType Тип усовершенствованной подписи (см. CADESCOM_CADES_TYPE). По умолчанию CAdES-X Long Type 1.
        // bDetached Вид подписи: отделенная (true) или совмещенная (false). По умолчанию совмещенная.
        // EncodingType Кодировка возвращаемой подписи (см. CAPICOM.CAPICOM_ENCODING_TYPE). По умолчанию CAPICOM_ENCODE_BASE64.
        logger.add({
          msg: '[Подписание] Подписываем данные',
          signer,
          CADESCOM_CADES_BES: cadesplugin.CADESCOM_CADES_BES,
          isDetached,
        });
        signedMessage = await signedData.SignCades(
          signer,
          cadesplugin.CADESCOM_CADES_BES,
          isDetached,
        );
      }
      data.file.sign = {
        certificate: certificate,
        content: signedMessage,
      };

      logger.add({ msg: '[Подписание] Данные подписаны', res: data });
      logger.add('[Подписание] Приступаем к проверке созданной подписи');

      verifyResult = await this.verify(data);

      if (!verifyResult) {
        logger.add(
          {
            msg: '[Подписание] Ошибка при проверке созданной подписи',
            res: data,
          },
          true,
        );
        throw '[Подписание] Ошибка при проверке созданной подписи';
      }
      logger.add({
        msg: '[Подписание] Созданная подпись корректна',
        res: data,
      });

      return data;
    } catch (err) {
      logger.add({ msg: '[Подписание] Подписание не удалось', err: err }, true);

      return false;
    }
  }

  /**
   * Проверка подписанных данных
   * @param {Object} sign - Объект содержащий подписанные данные и параметры
   * @returns {Promise<Array|Boolean>}
   */
  async verify(sign) {
    if (!sign?.file?.content) {
      logger.add({ msg: '[Проверка] Некорректные данные на вход', sign: sign });
      return false;
    }
    const isCoSign = !!sign.isCoSign,
      isDetached = !!sign.isDetached;
    let propsetContent = sign.file.content;
    logger.add({ msg: '[Проверка] Начинаем проверку', sign: sign });

    try {
      const signedData = await this._createSignedData();

      if (isDetached && isCoSign) {
        if (!sign.file.original || sign.file.original === '') {
          logger.add(
            '[Соподписание] Отсутствует исходный подписываемый файл',
            true,
          );
          throw '[Соподписание] Отсутствует исходный подписываемый файл';
        }
        propsetContent = sign.file.original;
      }

      if (isDetached) {
        await signedData.propset_ContentEncoding(
          cadesplugin.CADESCOM_BASE64_TO_BINARY,
        );
        await signedData.propset_Content(propsetContent).catch(err => {
          throw { msg: '[propset_Content] Неверный формат данных', err: err };
        });
      }
      // VerifyCades (SignedMessage, CadesType, bDetached)
      // SignedMessage Проверяемое подписанное сообщение.
      // CadesType Тип усовершенствованной подписи (см. CADESCOM_CADES_TYPE), на соответствие которому следует проверить указанную подпись. По умолчанию CAdES-X Long Type 1.
      // bDetached Вид подписи: отделенная (true) или совмещенная (false). По умолчанию совмещенная.

      await signedData.VerifyCades(
        sign.file.sign.content,
        cadesplugin.CADESCOM_CADES_BES,
        isDetached,
      );
      const signs = await this._signInfo(signedData);
      logger.add({ msg: '[Проверка] Результаты проверки', res: signs });
      return signs;
    } catch (err) {
      logger.add(
        {
          msg: '[Проверка] Проверка завершилась ошибкой',
          err: err,
          sign: sign,
        },
        true,
      );
      return false;
    }
  }

  /**
   * Проверка cadesplugin на готовность
   * (Попытка создать Store object)
   */
  async _check() {
    try {
      await this._createStore();
      this.pluginReady = true;

      return true;
    } catch (err) {
      this.pluginReady = false;

      return false;
    }
  }

  /**
   * Создаем новый экземпляр CAdESCOM.Store
   * Подробнее https://cpdn.cryptopro.ru/content/cades/class_store.html
   * @returns {CAdESCOM.Store}
   */
  async _createStore() {
    return await cadesplugin.CreateObjectAsync('CAdESCOM.Store');
  }

  /**
   * Создаем новый экземпляр CAdESCOM.CPSigner
   * Подробнее https://cpdn.cryptopro.ru/content/cades/class_c_ad_e_s_c_o_m_1_1_c_p_signer.html
   * @returns {CAdESCOM.CPSigner}
   */
  async _createSigner() {
    return await cadesplugin.CreateObjectAsync('CAdESCOM.CPSigner');
  }

  /**
   * Создаем новый экземпляр CAdESCOM.CadesSignedData
   * Подробнее https://cpdn.cryptopro.ru/content/cades/class_c_ad_e_s_c_o_m_1_1_cades_signed_data.html
   * @returns {CAdESCOM.CadesSignedData}
   */
  async _createSignedData() {
    return await cadesplugin.CreateObjectAsync('CAdESCOM.CadesSignedData');
  }

  /**
   * Извлекаем информацию о подписях из объекта SignedData
   * @param {CAdESCOM.CadesSignedData} signedData
   * @returns {Array<Object>}
   */
  async _signInfo(signedData) {
    const signers = await signedData.Signers;
    const count = await signers.Count;

    const signs = [];

    for (let i = 1; i <= count; i += 1) {
      const signer = await signers.Item(i);
      const certificate = await signer.Certificate;

      const sign = {
        ts: await signer.SigningTime,
        cert: await this.parseCertificate(certificate),
      };

      signs.push(sign);
    }

    return signs;
  }

  /**
   * Извлекаем информацию о владельце сертификата
   * @param {Certificate} certificate
   * @returns {Object}
   */
  async _extractSubjectName(certificate) {
    const subject = await certificate.SubjectName;
    return this._parseDN(subject);
  }

  /**
   * Извлекаем информацию о издателе сертификата
   * @param {Certificate} certificate
   * @return {Object}
   */
  async _extractIssuerName(certificate) {
    const issuer = await certificate.IssuerName;
    return this._parseDN(issuer);
  }

  /**
   * Преобразуем DN строку в объект
   * @param {String} dn
   * @returns {Object}
   */
  _parseDN(dn) {
    const tags = {
      CN: 'name',
      S: 'region',
      STREET: 'address',
      O: 'company',
      OU: 'postType',
      T: 'post',
      ОГРН: 'ogrn',
      СНИЛС: 'snils',
      ИНН: 'inn',
      E: 'email',
      L: 'city',
    };

    let buf = dn;
    const fields = [...buf.matchAll(/([a-zA-Z0-9А-Яа-я]+)=/g)].reduceRight(
      (acc, cur) => {
        let v = buf.substring(cur.index);
        v = v.replace(cur[0], '');
        v = v.replace(/\s*"?(.*?)"?,?\s?$/, '$1');
        v = v.replace(/""/g, '"');

        const tag = cur[1];

        if (tags[tag]) {
          acc[tags[tag]] = v;
        }

        buf = buf.substring(0, cur.index);

        return acc;
      },
      {},
    );

    return fields;
  }
  /**
   * форматируем дату
   * @param {certDate} date
   * @return {String}
   */
  async _normalizeDate(certDate) {
    let date = new Date(await certDate);
    function format2Digit(digit) {
      return digit < 10 ? '0' + digit : digit;
    }
    let normalizeDate = `${format2Digit(date.getUTCDate())}.${format2Digit(
      date.getMonth() + 1,
    )}.${format2Digit(date.getFullYear())} ${format2Digit(
      date.getUTCHours(),
    )}:${format2Digit(date.getUTCMinutes())}:${format2Digit(
      date.getUTCSeconds(),
    )}`;

    return normalizeDate;
  }

  /**
   * Формируем полную информацию о сертификата
   * @param {Certificate} certificate
   * @returns {Object}
   */
  async parseCertificate(certificate) {
    const isValid = await certificate.IsValid();

    return {
      $original: certificate,
      subject: await this._extractSubjectName(certificate),
      issuer: await this._extractIssuerName(certificate),
      version: await certificate.Version,
      serialNumber: await certificate.SerialNumber,
      thumbprint: await certificate.Thumbprint,
      validFrom: await this._normalizeDate(certificate.ValidFromDate),
      validTo: await this._normalizeDate(certificate.ValidToDate),
      hasPrivate: await certificate.HasPrivateKey(),
      isValid: await isValid.Result,
      certToExport: await certificate.Export(this.CAPICOM_ENCODE_BASE64), // Экспортированный сертификат в base64
    };
  }
}
