<template>
  <div class="create-stmt">
    <form v-if="!stmtCreated" @submit.prevent="submitHandler">
      <div class="create-stmt__pages" :data-current-page="currentPage">
        <div
          class="create-stmt__pages-wrap"
          :style="`transform: translateX(${
            currentPage === 1 ? '0' : `-${100 * (currentPage - 1)}%`
          })`"
        >
          <first-page
            :form-data="formData"
            :class="{ 'create-stmt__page--active': currentPage === 1 }"
            @update-form="updateFormData"
            data-page="1"
          />
          <second-page
            @update:loading="status => (loading = status)"
            :form-data="formData"
            :class="{ 'create-stmt__page--active': currentPage === 2 }"
            @update-form="updateFormData"
            data-page="2"
          />
          <third-page
            :form-data="formData"
            :class="{ 'create-stmt__page--active': currentPage === 3 }"
            @update-form="updateFormData"
            data-page="3"
          />
          <final-page
            :form-data="formData"
            :class="{ 'create-stmt__page--active': currentPage === 4 }"
            data-page="4"
          />
        </div>

        <div class="relative">
          <bn-step-indicator
            :current-page="currentPage"
            :first-page-filled="firstPageFilled"
            :second-page-filled="secondPageFilled"
            :third-page-filled="thirdPageFilled"
            :form-filled="formFilled"
            :errorOnFirstPage="errorOnFirstPage"
            :errorOnSecondPage="errorOnSecondPage"
            :errorOnThirdPage="errorOnThirdPage"
          />

          <div
            v-if="errorsList.length > 0 || loading"
            class="create-stmt__alerts flex justify-center items-center"
          >
            <bn-preloader v-if="loading" width="30px" />

            <div v-if="errorsList.length > 0" class="create-stmt__errors">
              <bn-icon :size="16" icon="alert-outline" class="mr-2" />
              <div class="create-stmt__errors-list">
                <div
                  v-for="error in errorsList"
                  :key="error.name"
                  @click="currentPage = error.page || currentPage"
                  class="create-stmt__errors-item flex justify-start items-center"
                >
                  <bn-icon class="mr-2" size="16" icon="alert-outline" />
                  <span class="flex-1" :title="error.msg">
                    <b v-if="error.title">{{ error.title }} - </b>
                    {{ error.msg }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="{
            'justify-end': currentPage === 1,
            'justify-between': currentPage > 1,
          }"
          class="flex"
        >
          <bn-button v-if="currentPage > 1" @click="--currentPage"
            >Назад
          </bn-button>

          <bn-button
            v-if="currentPage < 4"
            color="success"
            :disabled="this.loading"
            @click="++currentPage"
            >Далее
          </bn-button>

          <bn-button
            v-if="currentPage === 4"
            :disabled="sendingNotAllowed"
            color="success"
            type="submit"
            >Создать
          </bn-button>
        </div>
      </div>
    </form>
    <div v-else><h2>Заявка успешно создана!</h2></div>

    <bn-modal
      :show-header="false"
      ref="confirmCreate"
      class="bn-overlay--light"
    >
      <p class="confirmation">
        Функционал удаления заявки недоступен, вы подтверждаете добавления
        данной заявки?
      </p>
    </bn-modal>
  </div>
</template>

<script>
import formConfig from './create-stmt-config.json';

import FirstPage from './BnCreateStmtFirstPage.vue';
import SecondPage from './BnCreateStmtSecondPage.vue';
import ThirdPage from './BnCreateStmtThirdPage.vue';
import FinalPage from './BnCreateStmtFinalPage';
import BnPreloader from '../ui/BnPreloaderForButton.vue';
import BnStepIndicator from './BnCreateStmtStepIndicator.vue';

import { createStmt } from './api-create-stmt.js';

export default {
  components: {
    FirstPage,
    SecondPage,
    ThirdPage,
    FinalPage,
    BnPreloader,
    BnStepIndicator,
  },

  emit: ['close'],

  data() {
    return {
      filter: '',
      formData: formConfig.data,
      totalPage: 4,
      currentPage: 1,
      loading: false,
      stmtCreated: false,

      firstPageEmptyFields: [],
      secondPageEmptyFields: [],
      thirdPageEmptyFields: [],

      errors: [],
      validationErrors: [],
    };
  },

  computed: {
    errorsList() {
      return [...this.errors, ...this.validationErrors];
    },

    sendingNotAllowed() {
      return !this.formFilled || this.loading || this.hasValidationError;
    },

    firstPageFilled() {
      return this.firstPageEmptyFields.length === 0;
    },

    secondPageFilled() {
      return this.secondPageEmptyFields.length === 0;
    },

    thirdPageFilled() {
      return (
        this.thirdPageEmptyFields.length === 0 &&
        this.formData.provision_sum.value > 0 &&
        this.formData.base_tariff.value > 0
      );
    },

    formFilled() {
      return (
        this.firstPageFilled && this.secondPageFilled && this.thirdPageFilled
      );
    },

    errorOnFirstPage() {
      return (
        this.validationErrors.reduce((accum, value) => {
          return value.page === 1 ? [...accum, value.name] : accum;
        }, []).length > 0
      );
    },

    errorOnSecondPage() {
      return (
        this.validationErrors.reduce((accum, value) => {
          return value.page === 2 ? [...accum, value.name] : accum;
        }, []).length > 0
      );
    },

    errorOnThirdPage() {
      return (
        this.validationErrors.reduce((accum, value) => {
          return value.page === 3 ? [...accum, value.name] : accum;
        }, []).length > 0
      );
    },

    hasValidationError() {
      return (
        this.errorOnFirstPage || this.errorOnSecondPage || this.errorOnThirdPage
      );
    },
  },

  watch: {
    formData: {
      deep: true,
      handler: function (newFormData, oldFormData) {
        this.checkFieldsFullness(newFormData);
        Object.keys(newFormData).forEach(key => {
          if (newFormData[key].value !== oldFormData[key].value) {
            this.formData[key].hasError = false;

            this.validationErrors = this.validationErrors.filter(error => {
              return error.name !== key;
            });
          }
        });
      },
    },
  },

  methods: {
    checkFieldsFullness(formData) {
      this.firstPageEmptyFields = [];
      this.secondPageEmptyFields = [];
      this.thirdPageEmptyFields = [];

      Object.keys(formData).forEach(key => {
        if (formData[key].required && !formData[key].value) {
          if (formData[key].page === 1) this.firstPageEmptyFields.push(key);
          if (formData[key].page === 2) this.secondPageEmptyFields.push(key);
          if (formData[key].page === 3) this.thirdPageEmptyFields.push(key);
        }
      });
    },

    updateFormData({ name, value, uuid, isBn24 }) {
      // Новый объект создается, чтобы отрабатывал watch formData
      const updatedForm = JSON.parse(JSON.stringify(this.formData));

      // сброс данных поля
      if (value === null) {
        updatedForm[name] = formConfig.data[name];
        this.formData = updatedForm;
        return;
      }

      updatedForm[name].hasError = false;

      if (value) updatedForm[name].value = value;
      if (uuid) updatedForm[name].uuid = uuid;
      if (isBn24 !== undefined) updatedForm[name].isBn24 = isBn24;

      this.formData = updatedForm;
    },

    async submitHandler() {
      const confirmed = await this.$refs.confirmCreate.open();

      if (!confirmed) return;

      this.validationErrors = [];
      this.errors = [];
      this.loading = true;
      const { ok, status, response } = await createStmt(this.formData);
      this.loading = false;

      if (!ok && status === 422) {
        Object.entries(response.error.errors).forEach(([name, errorMsg]) => {
          const keysArray = name.split('.');
          const primaryKey = keysArray[1];
          const nestedKey = keysArray[2];

          const error = {
            name: primaryKey,
            title: primaryKey,
            msg: errorMsg,
          };

          if (primaryKey in this.formData) {
            this.formData[primaryKey].hasError = true;
            error.title = this.formData[primaryKey].title;
            error.page = this.formData[primaryKey].page;
          }
          if (primaryKey === 'product' && nestedKey === 'type') {
            this.formData.product_type.hasError = true;
            error.title = 'Тип продукта';
            error.page = this.formData.product_type.page;
          }
          if (primaryKey === 'bank_uuid') {
            this.formData.bank.hasError = true;
            error.name = 'bank';
            error.title = 'Партнер(Банк)';
            error.page = this.formData.bank.page;
          }

          this.validationErrors.push(error);
        });

        return;
      }

      if (!ok) {
        this.errors.push({
          msg: 'Ошибка при создании заявки',
        });

        return;
      }

      this.stmtCreated = true;

      setTimeout(() => {
        this.formData = Object.assign({}, formConfig.data);
        this.stmtCreated = false;
        this.$emit('close');
      }, 2000);
    },
  },
};
</script>

<style lang="scss">
.create-stmt {
  width: 500px;

  .bn-input {
    margin-bottom: 0.75rem;
  }
  &__pages {
    overflow: hidden;
  }
  &__pages-wrap {
    display: flex;
    transition: transform 0.4s ease-in-out;
    z-index: 100;
    position: relative;
  }
  &__page {
    width: 100%;
    min-width: 100%;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    &--active {
      opacity: 1;
    }
  }
  &__alerts {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-50%);
    z-index: 100;
  }
  &__errors {
    &-list {
      display: none;
      position: absolute;
      bottom: 0;
      right: 5px;
      min-width: 300px;
      padding: 5px;
      overflow: hidden;
      background-color: #fff;
      box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.12);
    }
    &-item {
      max-width: 280px;
      padding: 3px;
      cursor: pointer;

      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &:hover {
        background-color: #eee;
      }
    }

    &:hover &-list {
      display: flex;
      flex-direction: column;
    }

    svg {
      fill: $red;
    }
  }

  .confirmation {
    width: 300px;
    max-width: 100%;
    font-size: 1.2rem;
  }
}

.form-group {
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
}
</style>
