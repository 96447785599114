<template>
  <ul class="bn-indicator">
    <li
      :data-filled="firstPageFilled"
      :data-has-error="errorOnFirstPage"
      :data-active="currentPage === 1"
      class="bn-indicator__item"
    ></li>
    <li
      :data-filled="secondPageFilled"
      :data-has-error="errorOnSecondPage"
      :data-active="currentPage === 2"
      class="bn-indicator__item"
    ></li>
    <li
      :data-filled="thirdPageFilled"
      :data-has-error="errorOnThirdPage"
      :data-active="currentPage === 3"
      class="bn-indicator__item"
    ></li>
    <li
      :data-filled="formFilled"
      :data-active="currentPage === 4"
      class="bn-indicator__item"
    ></li>
  </ul>
</template>

<script>
export default {
  props: {
    currentPage: { type: Number, required: false },
    firstPageFilled: { type: Boolean, required: false, default: false },
    secondPageFilled: { type: Boolean, required: false, default: false },
    thirdPageFilled: { type: Boolean, required: false, default: false },
    formFilled: { type: Boolean, required: false, default: false },
    errorOnFirstPage: { type: Boolean, required: false, default: false },
    errorOnSecondPage: { type: Boolean, required: false, default: false },
    errorOnThirdPage: { type: Boolean, required: false, default: false },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.bn-indicator {
  display: flex;
  list-style-type: none;
  justify-content: center;
  align-items: center;

  &__item {
    width: 30px;
    height: 6px;
    background-color: #ccc;
    margin: 0 4px;
    opacity: 0.4;

    &[data-active='true'] {
      background-color: $color-primary;
      opacity: 1;
    }
    &[data-filled='true'] {
      background-color: $color-success;
    }
    &[data-has-error='true'] {
      background-color: $color-danger;
    }
  }
}
</style>
