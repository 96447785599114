<template>
  <bn-input :hasError="hasError" :label="label">
    <bn-select
      :mask="mask"
      :list="loadedList"
      :search="true"
      :loadable-list="true"
      :loading="loading"
      @clear="$emit('clear')"
      @selected="selected"
      @close="suggestions = null"
      v-model:value="value"
      v-model:query="query"
    />
  </bn-input>
</template>

<script>
import { getDadataInfo } from '../../services/api';

export default {
  TIMEOUT: 500, //timeout троттлинга, интервал в мс, с которым следует пропускать вызовы.
  props: {
    label: { type: String, required: false, default: '' },
    mask: { type: String, required: false, default: '' },
    loading: { type: Boolean, required: false, default: false },
    hasError: { type: Boolean, required: false, default: false },
  },
  emits: ['selected', 'clear', 'loading'],
  data() {
    return {
      value: '',
      query: '',
      list: {},
      timer: null,
      suggestions: null,
      selectedValue: null,
    };
  },
  computed: {
    loadedList() {
      if (!this.suggestions) return {};

      const list = {};
      this.suggestions.forEach((suggest, idx) => {
        list[idx] = { name: suggest.value, inn: suggest.data.inn };
      });
      return list;
    },
  },
  watch: {
    selectedValue(newValue) {
      this.$emit('selected', newValue);
    },
    query(newValue) {
      if (newValue.length < 3) {
        this.list = {};
        return;
      }

      if (this.timer) return;

      this.timer = setTimeout(() => {
        this.getDadataInfo(newValue);

        clearTimeout(this.timer);
        this.timer = null;
      }, this.$options.TIMEOUT);
    },
  },
  methods: {
    selected(item) {
      const idx = Object.keys(item)[0];

      this.selectedValue = this.suggestions[idx];
      this.suggestions = null;
    },
    async getDadataInfo(query) {
      this.$emit('loading', true);
      const { ok, response } = await getDadataInfo(query);
      this.$emit('loading', false);
      if (!ok || !response?.suggestions) return;

      this.suggestions = response.suggestions;
    },
  },
};
</script>

<style></style>
