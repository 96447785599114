import { fetchData } from '../../services/fetch';

const base = process.env.VUE_APP_API_URL;

/* KV */

/**
 * Получить данные таблицы KV
 * @param {String} period - выбранный период
 */
const getKvTableData = async period => {
  const sortRequest = period ? `?period_dt=${period}` : '';

  try {
    const { ok, status, response } = await fetchData(
      `${base}/kv${sortRequest}`,
    );

    if (!ok) throw { status, response };

    return {
      ok: true,
      data: response.data,
    };
  } catch (error) {
    console.error('Ошибка при получении данных таблицы KV', error);

    return {
      ok: false,
      status: error?.status,
      response: error?.response,
      msg: 'Ошибка при получении данных таблицы KV',
      error,
    };
  }
};
/**
 * Пересчитать Банк
 * @param {Object} params
 * @param {String} params.uuid - uuid Банка
 * @param {String} params.period - выбранный период
 */
const recalculateBank = async ({ uuid, period }) => {
  try {
    const { ok, status, response } = await fetchData({
      url: `${base}/kv/calculate?bank_uuid=${uuid}&period_dt=${period}`,
      method: 'PATCH',
    });

    if (!ok) throw { status, response };

    return { ok, data: response.data };
  } catch (error) {
    console.error('Ошибка при отправке запроса на банка перерасчёт', error);

    return {
      ok: false,
      status: error?.status,
      response: error?.response,
      msg: 'Ошибка при отправке запроса на банка перерасчёт',
      error,
    };
  }
};
/**
 * Сверить Банк
 * @param {Object} params
 * @param {String} params.uuid - uuid Банка
 * @param {String} params.period - выбранный период
 */
const verifyBank = async ({ uuid, period }) => {
  const periodDt = period ? `&period_dt=${period}` : '';

  try {
    const { ok, status, response } = await fetchData({
      url: `${base}/kv/switch_approve_status?bank_uuid=${uuid}${periodDt}`,
      method: 'PATCH',
    });

    if (!ok) throw { status, response };

    return { ok, data: response.data };
  } catch (error) {
    const errorMessage =
      error?.status === 405
        ? 'Изменения недоступны. Данный период уже сверен.'
        : 'Ошибка при сверке Банка.';

    console.error(errorMessage, error);

    return {
      ok: false,
      status: error?.status,
      response: error?.response,
      msg: errorMessage,
      error,
    };
  }
};

export { getKvTableData, recalculateBank, verifyBank };
