<template>
  <div class="bn-table__right-panel">
    <div class="period-selection">
      <span class="period-selection__title">Период:</span>
      <el-config-provider :locale="locale">
        <datepicker
          v-model="inputDate"
          type="month"
          placeholder="Выберите месяц"
          :disabled-date="disabledDate"
        />
      </el-config-provider>
    </div>

    <h2>
      Данные за
      {{ localizedCurrentDate }}
    </h2>

    <div class="summary-info">
      <p>
        <span>Сверено агентов:</span>
        <b
          >{{ total.approvedAgents ? total.approvedAgents : '0' }}
          {{ total.agents ? `из ${total.agents}` : '' }}</b
        >
      </p>
      <p>
        <span>Всего заявок:</span>
        <b>{{ total.stmts ? total.stmts : '0' }}</b>
      </p>

      <p>
        <span>Сумма обеспечений:</span>
        <b
          >{{
            total.provisionSum ? toCurrency(total.provisionSum) : '0'
          }}
          руб.</b
        >
      </p>
      <p>
        <span>Сумма оплат:</span>
        <b
          >{{ total.finalTariff ? toCurrency(total.finalTariff) : '0' }} руб.</b
        >
      </p>
      <p>
        <span>Сумма АВ:</span
        ><b>{{ total.avSum ? toCurrency(total.avSum) : '0' }} руб.</b>
      </p>
    </div>
  </div>
</template>

<script>
import ru from 'element-plus/lib/locale/lang/ru';
import { ElConfigProvider } from 'element-plus';
import { formattedDate, toCurrency } from '../../helpers/index';

export default {
  components: {
    ElConfigProvider,
  },
  props: {
    normalizedCurrentDate: { type: String, required: false, default: '' },
    localizedCurrentDate: { type: String, required: false, default: '' },
    total: { type: Object, required: false, default: () => {} },
  },
  data() {
    return {
      locale: ru,
      inputDate: null,
    };
  },
  computed: {
    period() {
      return this.$store.state.filter.period;
    },
  },
  methods: {
    disabledDate(time) {
      return time.getTime() > Date.now();
    },
    toCurrency,
  },
  watch: {
    inputDate() {
      this.$store.dispatch('SET_FILTER', {
        name: 'period',
        value: formattedDate(this.inputDate),
      });
    },
    period(newValue) {
      if (!newValue) {
        this.inputDate = this.normalizedCurrentDate;
        return;
      }

      this.inputDate = newValue;
    },
    normalizedCurrentDate(newValue) {
      if (!this.period) {
        this.inputDate = newValue;
        return;
      }

      this.inputDate = this.period;
    },
  },
};
</script>
