<template>
  <span
    class="sign-status"
    :class="{ 'sign-status--success': completed }"
    :title="
      completed ? `Отчёт подписан обеими сторонами` : `Подписание не завершено`
    "
  >
    <bn-icon :size="20" :icon="completed ? 'check' : 'alert-circle-outline'" />
  </span>
</template>

<script>
export default {
  props: {
    row: { type: Object, required: false, default: () => {} },
  },
  computed: {
    completed() {
      return (
        this.row?.accountant_status?.status && this.row?.agent_status?.status
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.sign-status {
  fill: #ccc;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  &--success {
    background-color: $color-success;
    fill: #fff;
  }
}
</style>
