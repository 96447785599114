<template>
  <span
    class="sign-status mr-1"
    :class="{ 'sign-status--success': accountantSigned }"
    :title="
      accountantSigned
        ? `Подписано бухгалтером ${formattedDate(
            row.accountant_status.updated_at,
          )} ${formattedTime(row.accountant_status.updated_at)}`
        : `Требуется подписание бухгалтером`
    "
  >
    <bn-icon :size="20" icon="file-sign" />
  </span>
  <span
    class="sign-status"
    :class="{ 'sign-status--success': agentSigned }"
    :title="
      agentSigned
        ? `Подписано ${row.agent_name} ${formattedDate(
            row.agent_status.updated_at,
          )} ${formattedTime(row.agent_status.updated_at)}`
        : `Требуется подписание агентом`
    "
  >
    <bn-icon :size="20" icon="file-sign" />
  </span>
</template>

<script>
import { formattedDate, formattedTime } from '../../../helpers/index';
export default {
  props: {
    row: { type: Object, required: false, default: () => {} },
  },
  computed: {
    agentSigned() {
      return this.row?.agent_status?.status;
    },
    accountantSigned() {
      return this.row?.accountant_status?.status;
    },
  },
  methods: { formattedDate, formattedTime },
};
</script>

<style lang="scss" scoped>
.sign-status {
  fill: #ccc;

  &--success {
    fill: $color-success;
  }
}
</style>
