<template>
  <bn-input :hasError="hasError" label="Менеджер">
    <bn-select
      v-model:query="managerFilter"
      :list="normalizedManagerList"
      :search="true"
      :loading="loading"
      :loadable-list="true"
      @clear="clearSelected"
      @selected="selected = $event"
  /></bn-input>
</template>

<script>
import { getManagerList } from './api-create-stmt';

export default {
  TIMEOUT: 500, //timeout троттлинга, интервал в мс, с которым следует пропускать вызовы.

  emits: ['selected', 'clear', 'loading'],

  props: {
    label: { type: String, required: false, default: '' },
    mask: { type: String, required: false, default: '' },
    loading: { type: Boolean, required: false, default: false },
    hasError: { type: Boolean, required: false, default: false },
  },

  data() {
    return {
      selected: null,
      managerFilter: null,
      managerList: null,
      timer: null,
    };
  },

  computed: {
    normalizedManagerList() {
      if (!this.managerList) return {};
      const result = {};

      Object.keys(this.managerList).forEach(key => {
        result[key] = this.managerList[key].name;
      });
      return result;
    },
  },

  watch: {
    selected(newValue) {
      this.$emit('selected', newValue);
    },

    async managerFilter(query) {
      if (query.length < 5 || this.timer) return;

      this.timer = setTimeout(async () => {
        this.$emit('loading', true);
        const { ok, managers } = await getManagerList(query);
        this.$emit('loading', false);
        if (!ok) return;

        this.managerList = managers;
        clearTimeout(this.timer);
        this.timer = null;
      }, this.$options.TIMEOUT);
    },
  },

  methods: {
    clearSelected() {
      this.selected = null;
      this.$emit('clear');
    },
  },
};
</script>

<style></style>
