<template>
  <el-config-provider :locale="locale">
    <datepicker ref="input" v-model="input" :disabled-date="disabledDate" />
  </el-config-provider>
</template>

<script>
import ru from 'element-plus/lib/locale/lang/ru';
import { ElConfigProvider } from 'element-plus';

export default {
  components: {
    ElConfigProvider,
  },
  emits: ['selected'],
  data() {
    return {
      locale: ru,
      input: null,
    };
  },
  watch: {
    input() {
      this.$emit('selected', this.input);
    },
  },
  methods: {
    disabledDate(time) {
      return time.getTime() > Date.now();
    },
  },
};
</script>

<style></style>
