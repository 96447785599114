/**
 * @typedef {function()} addedCallback
 */

/**
 * Отправляем данные
 * @param {addedCallback} callee callee, функция, которую надо «откладывать»;
 * @param {Number} timeout интервал времени, интервал в мс, спустя которое функцию следует вызывать.
 */

// - интервал времени, спустя которое функцию следует вызывать.
export default function debounce(callee, timeout) {
  return function perform(...args) {
    let previousCall = this.lastCall;

    this.lastCall = Date.now();

    if (previousCall && this.lastCall - previousCall <= timeout) {
      clearTimeout(this.lastCallTimer);
    }

    this.lastCallTimer = setTimeout(() => callee(...args), timeout);
  };
}
