<template>
  <td
    rowspan="1"
    colspan="1"
    class="n-table__cell"
    :data-column-name="column.name"
  >
    <div class="cell" :style="{ maxWidth: column.width + 'px' }">
      <slot> {{ normalizedValue() }}</slot>
    </div>
  </td>
</template>

<script>
import { toCurrency } from '../../helpers/index';

export default {
  props: {
    column: { type: Object, required: false, default: () => {} },
    value: { type: [String, Number, Object], required: false, default: '' },
  },
  methods: {
    normalizedValue() {
      if (this.column.type === 'boolean') {
        return this.value ? 'Да' : 'Нет';
      }
      if (this.column.type === 'money') {
        return toCurrency(this.value);
      }

      return this.value;
    },
  },
};
</script>

<style></style>
