import { createApp } from 'vue';
import router from './router';
import { store } from './store';
import App from './App.vue';

import money from 'v-money';
import VueSweetalert2 from 'vue-sweetalert2';

import { ElDatePicker } from 'element-plus';
import 'element-plus/es/components/date-picker/style/css';

import VueTheMask from 'vue-the-mask';

import './assets/scss/style.scss';
import 'sweetalert2/dist/sweetalert2.min.css';

import BnButton from './components/ui/BnButton.vue';
import BnInput from './components/ui/BnInput.vue';
import BnSelect from './components/ui/BnSelect.vue';
import BnIcon from './components/ui/BnIcon.vue';
import BnButtonGroup from './components/ui/BnButtonGroup.vue';
import BnDropdownMenu from './components/ui/BnDropdownMenu.vue';
import BnModal from './components/ui/BnModal.vue';

createApp(App)
  .use(router)
  .use(store)
  .use(money)
  .use(VueTheMask)
  .use(VueSweetalert2)
  .component('datepicker', ElDatePicker)
  .component('bn-icon', BnIcon)
  .component('bn-button', BnButton)
  .component('bn-input', BnInput)
  .component('bn-modal', BnModal)
  .component('bn-select', BnSelect)
  .component('bn-button-group', BnButtonGroup)
  .component('bn-dropdown-menu', BnDropdownMenu)
  .directive('click-outside', {
    mounted(el, binding) {
      el.clickOutsideEvent = function (event) {
        if (!(el === event.target || el.contains(event.target))) {
          binding.value(event, el);
        }
      };
      document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted(el) {
      document.body.removeEventListener('click', el.clickOutsideEvent);
    },
  })
  .mount('#app');
