<template>
  <section class="create-stmt__page flex flex-col">
    <div class="form-group flex-1">
      <div class="flex">
        <div class="w-1/2">
          <bn-select-dadata
            label="Клиент"
            :hasError="formData.client.hasError"
            :loading="loading"
            @loading="$emit('update:loading', $event)"
            @clear="clearSelectedClient"
            @selected="clientSelected"
          />
          <bn-select-dadata
            label="Агент"
            :hasError="formData.agent.hasError"
            :loading="loading"
            @loading="$emit('update:loading', $event)"
            @clear="clearSelectedAgent"
            @selected="agentSelected"
          />
          <bn-select-manager
            :hasError="formData.manager.hasError"
            :loading="loading"
            @loading="$emit('update:loading', $event)"
            @clear="clearSelectedManager"
            @selected="managerSelected"
          />
        </div>
        <div class="w-1/2 px-4 py-0">
          <div v-if="selectedClient">
            <p>
              <b>{{ selectedClient.value }}</b>
            </p>
            <p class="my-2" v-if="selectedClient.data.inn">
              ИНН: {{ selectedClient.data.inn }}
            </p>
            <p class="my-2" v-if="selectedClient.data.ogrn">
              ОГРН: {{ selectedClient.data.ogrn }}
            </p>
          </div>
          <div v-if="selectedAgent">
            <p><b>Агент:</b></p>
            <p class="flex justify-start items-center">
              {{ selectedAgent.value }}
              <span
                v-if="formData.agent?.uuid && formData.agent?.isBn24"
                title="Агент зарегистрирован на БН24"
                class="label label--success px-1"
              >
                <bn-icon size="12" icon="check-circle" />
              </span>
              <span
                v-if="formData.agent?.uuid && !formData.agent?.isBn24"
                title="Данная организация добавлена вручную и не зарегистрирована на БН24"
                class="label label--warning px-1"
              >
                <bn-icon size="12" icon="alert-circle-outline" />
              </span>
              <span
                v-if="!formData.agent?.uuid"
                title="Агент не зарегистрирован в сервисах БН24"
                class="label label--danger px-1"
              >
                <bn-icon size="12" icon="alert-circle-outline" />
              </span>
            </p>
            <p>ИНН: {{ selectedAgent.data.inn }}</p>
          </div>
          <div v-if="selectedManager">
            <p><b>Менеджер:</b></p>
            <p>
              {{ valueFromObj(selectedManager) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BnSelectDadata from './BnSelectDadata.vue';
import BnSelectManager from './BnSelectManager.vue';

import { formattedDate } from '../../helpers/index';
import { checkClient, checkAgent } from './api-create-stmt';

export default {
  props: {
    loading: { type: Boolean, required: false, default: false },
    formData: { type: Object, required: false, default: () => {} },
  },

  emits: ['update-form', 'update:loading'],

  components: {
    BnSelectDadata,
    BnSelectManager,
  },

  data() {
    return {
      selectedAgent: null,
      selectedClient: null,
      selectedManager: null,
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    valueFromObj(obj) {
      return Object.values(obj)[0];
    },

    async clientSelected(value) {
      this.selectedClient = value;
      this.updateInput({ name: 'client', value });

      const inn = value.data.inn;

      if (!inn) return;

      this.$emit('update:loading', true);
      const { ok, data } = await checkClient(inn);
      this.$emit('update:loading', false);

      if (!ok) return;

      this.updateInput({ name: 'agent', uuid: data.uuid });
    },

    async agentSelected(value) {
      this.selectedAgent = value;
      this.updateInput({ name: 'agent', value });

      const inn = value.data.inn;

      if (!inn) return;
      this.$emit('update:loading', true);
      const { ok, data } = await checkAgent(inn);
      this.$emit('update:loading', false);
      if (!ok) return;

      this.updateInput({
        name: 'agent',
        uuid: data.uuid,
        isBn24: data.is_bn24,
      });
    },

    managerSelected(value) {
      this.selectedManager = value;
      this.updateInput({ name: 'manager', value });
    },

    updateInput(data) {
      this.$emit('update-form', data);
    },

    clearSelectedClient() {
      this.selectedClient = null;
      this.updateInput({ name: 'client', value: null });
    },

    clearSelectedAgent() {
      this.selectedAgent = null;
      this.updateInput({ name: 'agent', value: null });
    },

    clearSelectedManager() {
      this.selectedManager = null;
      this.updateInput({ name: 'manager', value: null });
    },

    formattedDate,
  },
};
</script>

<style lang="scss" scoped>
.label {
  display: inline-flex;

  &--success svg {
    fill: $color-success;
  }
  &--danger svg {
    fill: $color-danger;
  }
  &--warning svg {
    fill: $color-warning;
  }
}
</style>
