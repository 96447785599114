import { getJwtToken } from './authentication-service';

let controller;

/**
 * Отправка запросов на основе Fetch API
 * @param {Object | String} options Строка URL если простой GET или Объект с настройками и данными для передачи
 * @param {String} options.url - URL запроса
 * @param {Object} options.headers - Заголовки запроса
 * @param {String} options.method - Метод запроса по умолчанию 'GET'
 * @param {String} options.data - Данные запроса
 * @param {Boolean} options.stringify - нужно ли преобразовывать данные запроса в JSON (JSON.stringify(data)), по-умолчанию TRUE
 * @param {Boolean} options.parse - нужно ли преобразовывать ответ из JSON (response.json()), по-умолчанию TRUE
 * @param {Number} options.timeout - время ожидания ответа Мс
 * @returns {Promise<Object>} response - {ok, status, response}
 */
const fetchData = async options => {
  let {
    url,
    headers,
    method,
    data,
    mode,
    stringify = true,
    parse = true,
    timeout = 10000,
  } = options;
  let response;

  controller = new AbortController();

  const JWT_TOKEN = 'Bearer ' + getJwtToken();
  const timer = setTimeout(() => {
    console.error('Время ожидания истекло');

    controller.abort();
  }, timeout);

  if (typeof options === 'string') url = options;

  const init = {
    headers: headers || {},
    method: method || 'GET',
    mode: mode || 'cors', // same-origin, no-cors
  };

  init.signal = controller.signal;

  if (!('Content-Type' in init.headers)) {
    init.headers['Content-Type'] = 'application/json;charset=utf-8';
  }

  if (
    !('Authorization' in init.headers) &&
    !('X-Authorization' in init.headers)
  ) {
    init.headers.Authorization = JWT_TOKEN;
  }

  if (data) init.body = stringify ? JSON.stringify(data) : data;

  try {
    response = await fetch(url, init);

    clearTimeout(timer);

    if (!response.ok) {
      throw new Error(
        `Соединение не удалось ${url}` +
          `, получен ответ сервера ${response.status}`,
      );
    }

    return {
      ok: true,
      status: response?.status,
      response: parse ? await response.json() : response,
    };
  } catch (error) {
    clearTimeout(timer);

    console.error('Ошибка при попытке соединения', error);

    let status = response?.status;

    if (error?.name === 'AbortError') status = 'AbortError';

    return {
      ok: false,
      status,
      response,
    };
  }
};

/**
 * Отмена запроса
 */
const abortFetch = () => controller.abort();

export { fetchData, abortFetch };
