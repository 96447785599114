<template>
  <section class="create-stmt__page flex flex-col">
    <div class="summary form-group flex-1">
      <h2>Информация по заявке</h2>
      <p>
        <span class="summary__name">Номер заявки:</span>
        <b>{{ formData.number.value || '-' }}</b>
      </p>
      <p>
        <span class="summary__name">Дата оплаты:</span>
        <b>{{ formData.paid_dt.value || '-' }}</b>
      </p>
      <p>
        <span class="summary__name">Партнер (Банк):</span>
        <b>{{ valueFromObj(formData.bank.value) || '-' }}</b>
      </p>
      <div class="flex">
        <p>
          <span class="summary__name">Продукт:</span>
          <b>
            {{ valueFromObj(formData.product.value) }}
            ({{ valueFromObj(formData.product_type.value) }}) -
            <b>{{ valueFromObj(formData.law.value) }}</b>
          </b>
        </p>
      </div>
      <p>
        <span class="summary__name">Клиент:</span>
        <b>
          {{ formData.client.value?.value || '-' }} (ИНН:
          {{ formData.client.value?.data?.inn || '-' }})</b
        >
      </p>
      <p>
        <span class="summary__name">Агент:</span
        ><b> {{ formData.agent.value?.value || '-' }}</b>
      </p>
      <p>
        <span class="summary__name">Менеджер:</span
        ><b> {{ valueFromObj(formData.manager.value) || '-' }}</b>
      </p>

      <hr />

      <table cellpadding="0" cellspacing="0" class="summary__table">
        <tr>
          <th>Базовый тариф:</th>
          <th>Размер обеспечения:</th>
          <th>Превышение менеджера:</th>
          <th>Превышение агента:</th>
        </tr>
        <tr>
          <td>{{ toCurrency(formData.base_tariff.value) }}</td>
          <td>{{ toCurrency(formData.provision_sum.value) }}</td>
          <td>{{ toCurrency(formData.manager_commission.value) }}</td>
          <td>{{ toCurrency(formData.agent_commission.value) }}</td>
        </tr>
        <tr>
          <td colspan="4" class="summary__table-final">
            <div class="flex justify-end items-center">
              <span class="mr-2">Итого:</span>
              <b> {{ toCurrency(formData.final_tariff.value) }}</b>
            </div>
          </td>
        </tr>
      </table>
      <p class="summary__info">
        После добавления заявки будет произведен пересчет КВ и АВ за указанный
        период
      </p>
    </div>
  </section>
</template>

<script>
import { toCurrency } from '../../helpers/index';

export default {
  props: {
    formData: { type: Object, required: false, default: () => {} },
  },

  methods: {
    valueFromObj(obj) {
      return obj ? Object.values(obj)[0] : '-';
    },

    toCurrency,
  },
};
</script>

<style lang="scss" scoped>
.summary {
  p {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 0 10px 0;

    b {
      flex: 1;
    }
  }
  &__name {
    display: inline-block;
    width: 120px;
  }
  &__table {
    border-collapse: separate;

    th,
    td {
      text-align: left;
      padding: 10px 5px;
      border-right: none;
      width: 25%;
      border-bottom: 1px solid #ebeef5;
    }
    td.summary__table-final {
      border-bottom: 0;
    }
  }
  &__info {
    color: $color-warning;
  }
}
</style>
