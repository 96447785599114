<template>
  <div class="">
    <div class="filter-search">
      <input
        v-model="filter"
        placeholder="Введите наименование..."
        type="text"
      />

      <div class="checked-list py-2">
        <template v-for="(agent, key) in selectedAgents" :key="key">
          <bn-button icon="close" color="success" @click="uncheckAgent(key)"
            >{{ agent }}
          </bn-button>
        </template>
      </div>
    </div>
    <div class="checkbox-list">
      <p v-if="agentsFound.length === 0 && filter.length > 3">
        Нет подходящих элементов
      </p>
      <div
        class="checkbox-list__item"
        v-for="(agent, idx) in agentsFound"
        :key="idx"
      >
        <span @click="selectAgent(agent)" class="text-button">
          {{ agent.name }}
        </span>
      </div>
    </div>
    <div class="flex justify-end items-center pt-2">
      <bn-button
        @click="clearFilter"
        :disabled="loading"
        class="checkbox-list__clear"
        >Очистить
      </bn-button>
    </div>
  </div>
</template>

<script>
import { getAgentsList } from '../api-stmts';

export default {
  TIMEOUT: 500, //timeout троттлинга, интервал в мс, с которым следует пропускать вызовы.

  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['close'],
  data() {
    return {
      filter: '',
      agentsFound: [],
      timer: null,
    };
  },
  computed: {
    selectedAgents() {
      return this.$store.state.filter.agents;
    },
  },
  watch: {
    filter(newValue) {
      if (newValue.length < 3) {
        this.agentsFound = [];
        return;
      }

      if (this.timer) return;

      this.timer = setTimeout(() => {
        this.getAgents();

        clearTimeout(this.timer);
        this.timer = null;
      }, this.$options.TIMEOUT);
    },
  },
  methods: {
    async getAgents() {
      const { ok, data } = await getAgentsList(this.filter);

      if (!ok) return;

      this.agentsFound = data.items;
    },
    uncheckAgent(key) {
      const agents = {};

      Object.assign(agents, this.selectedAgents);
      delete agents[key];

      this.$store.dispatch('SET_FILTER', {
        name: 'agents',
        value: agents,
      });
    },
    selectAgent(agent) {
      const agents = {};

      Object.assign(agents, this.selectedAgents);
      agents[agent.uuid] = agent.name;

      this.$store.dispatch('SET_FILTER', {
        name: 'agents',
        value: agents,
      });
      this.$emit('close');
    },
    clearFilter() {
      this.$store.dispatch('SET_FILTER', {
        name: 'agents',
        value: {},
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-search {
  input {
    width: 100%;
  }
}
.checked-list {
  width: 250px;

  button {
    font-size: 0.8rem;
    height: auto;
    padding: 4px 12px 4px 4px;
    margin: 0 3px 3px 0;
  }
}
.checkbox-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 220px;
  width: 280px;
  overflow-x: hidden;
  overflow-y: scroll;
  margin: 5px -15px;
  padding: 5px 10px;
  box-shadow: inset -4px -4px 3px 0px rgba(50, 50, 50, 0.05),
    inset -2px 4px 4px 0px rgba(50, 50, 50, 0.05);

  &__item {
    padding: 6px 6px 6px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
  }
  &__clear {
    border: 0;
    text-decoration: underline;

    &:hover,
    &:focus {
      background-color: #fff;
    }
  }
}
</style>
