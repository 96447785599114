<template>
  <div class="bn-chat-wrapper" :data-is-open="isOpen">
    <slot name="main" :close="close"></slot>
  </div>
</template>

<script>
export default {
  currentPopupController: null,
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    sendMessage() {},
    open() {
      let resolve;
      let reject;
      const modalPromise = new Promise((ok, fail) => {
        resolve = ok;
        reject = fail;
      });

      this.$options.currentPopupController = { resolve, reject };
      this.isOpen = true;

      return modalPromise;
    },
    close() {
      this.$options.currentPopupController.resolve(false);
      this.isOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.bn-chat-wrapper {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 500;
  transform: translateX(400px);
  transition: transform 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
  width: 380px;
  background-color: #fff;
  box-shadow: -5px 0px 5px -5px rgba(34, 60, 80, 0.6);

  &[data-is-open='true'] {
    transform: translateX(0);
  }
}
</style>
