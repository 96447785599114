<template>
  <div class="bn-alerts-box"><slot></slot></div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['alertBox']),
  },
  watch: {
    alertBox: {
      deep: true,
      handler: function () {
        const alert = this.alertBox[0];

        this.$swal({
          icon: alert.type,
          title: alert.text,
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 3000,
        });
      },
    },
  },
};
</script>

<style></style>
