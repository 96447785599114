import { fetchData } from '../../services/fetch';

const base = process.env.VUE_APP_API_URL;

/**
 * Получить данные таблицы заявок
 * @param {String} searchParams - строка параметров запроса
 * @returns {Promise<Object>} response - Успех {ok, data} иначе {ok, status, response, msg, error}
 */
const getStmtsTableData = async searchParams => {
  try {
    const { ok, status, response } = await fetchData(
      `${base}/stmts?${searchParams}`,
    );

    if (!ok) throw { status, response };

    return {
      ok: true,
      data: response.data,
    };
  } catch (error) {
    console.error('Ошибка при получении данных таблицы заявок', error);

    return {
      ok: false,
      status: error?.status,
      response: error?.response,
      msg: 'Ошибка при получении данных таблицы заявок',
      error,
    };
  }
};

/**
 * Скачать отчёт
 * @param {String} filterRequestUrl - параметры запроса
 */
const getReport = async filterRequestUrl => {
  try {
    const { ok, status, response } = await fetchData({
      url: `${base}/stmts/report?${filterRequestUrl}`,
      parse: false,
    });

    if (!ok) throw { status, response };

    const blob = await response.blob();
    const file = new File([blob], 'report.xlsx');
    const link = document.createElement('a');

    link.download = file.name;
    link.href = URL.createObjectURL(file);
    link.click();
    URL.revokeObjectURL(link.href);

    return { ok: true, status, response };
  } catch (error) {
    console.error('Ошибка при скачивании отчёта', error);

    return {
      ok: false,
      status: error?.status,
      response: error?.response,
      msg: 'Ошибка при скачивании отчёта',
      error,
    };
  }
};

/**
 * Сохранить изменения заявки
 * @param {Object} params - объект с параметрами и данными
 * @param {String} params.uuid
 * @param {Object} params.stmt
 * @param {Object} params.stmtParams
 */
const saveStmtChanges = async ({ uuid, recalculate, stmt, stmtParams }) => {
  const data = preparedRequestData(stmt, stmtParams);

  try {
    const { ok, status, response } = await fetchData({
      url: `${base}/stmts/${uuid}?recalculate=${recalculate}`,
      method: 'PATCH',
      data,
    });

    if (!ok) throw { status, response };

    return { ok: true, stmt: response.data };
  } catch (error) {
    return {
      ok: false,
      status: error?.status,
      response: error?.response,
      msg: 'Ошибка при сохранении изменений в заявке',
      error,
    };
  }
};

// Подготовка данных для запроса на изменения заявки
const preparedRequestData = (changedStmt, changedStmtParams) => {
  const result = {};
  Object.keys(changedStmtParams).forEach(key => {
    if (key === 'agent.commission') {
      result.agent_commission = Number(changedStmtParams[key]);
      return;
    }
    if (key === 'manager.commission') {
      result.manager_commission = Number(changedStmtParams[key]);
      return;
    }
    if (key === 'av.sum') {
      result.av = {};
      result.av.sum = Number(changedStmtParams[key]);
      result.av.percent =
        Number(changedStmtParams[key]) / changedStmt.final_tariff;
      result.av.updated_at = new Date().toJSON();
      return;
    }
    if (key === 'kv.sum') {
      result.kv = {};
      result.kv.sum = Number(changedStmtParams[key]);
      result.kv.percent =
        Number(changedStmtParams[key]) / changedStmt.final_tariff;
      result.kv.updated_at = new Date().toJSON();
      return;
    }
    result[key] = changedStmtParams[key];
  });

  return result;
};

/**
 * Получить список агентов
 * @param {String} agentName - имя агента
 * @returns {Promise<Object>} response - Успех {ok, data} иначе {ok, status, response, msg, error}
 */
const getAgentsList = async agentName => {
  try {
    const { ok, status, response } = await fetchData(
      `${base}/agents${agentName ? `?name=${agentName}` : ''}`,
    );

    if (!ok) throw { status, response };

    return {
      ok: true,
      data: response.data,
    };
  } catch (error) {
    console.error('Ошибка при получении списка агентов', error);

    return {
      ok: false,
      status: error?.status,
      response: error?.response,
      msg: 'Ошибка при получении списка агентов',
      error,
    };
  }
};

export { getStmtsTableData, getReport, saveStmtChanges, getAgentsList };
