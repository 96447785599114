import { fetchData } from './fetch';

const AUTH_URL = process.env.VUE_APP_AUTH_URL;
const CLIENT_ID = process.env.VUE_APP_CLIENT_ID;

// Получить Jwt-Token
const getJwtToken = () => sessionStorage.getItem('jwt');

// Сохранить Jwt-Token в хранилище
const setJwtToken = token => {
  sessionStorage.setItem('jwt', token);
};

// Удалить Jwt-Token из хранилища
const removeJwtToken = () => {
  sessionStorage.removeItem('jwt');
};

// Отозвать Jwt-Token
const revokeJwtToken = async () => {
  const refreshToken = getRefreshToken();
  if (!refreshToken || refreshToken === 'undefined') return;

  const result = await fetchData(
    `${AUTH_URL}/logout/?refresh_token=${refreshToken}`,
  );
  console.log(result);
};

// Получить дату истечения Jwt токена
const getJwtTokenLifeTime = () => localStorage.getItem('lifetime');

// Сохранить время жизни Jwt токена в хранилище
const setJwtTokenLifeTime = date => {
  localStorage.setItem('lifetime', date * 1000);
};

// Удалить дату истечения Jwt токена из хранилища
const removeJwtTokenLifeTime = () => {
  localStorage.removeItem('lifetime');
};

// Получить дату истечения Jwt токена
const getExpirationDate = () => Number(localStorage.getItem('expired'));

// Сохранить дату истечения Jwt токена в хранилище время уменьшено на 1 минуту
const setExpirationDate = date => {
  localStorage.setItem('expired', new Date().getTime() + date * 1000 - 60000);
};

// Удалить дату истечения Jwt токена из хранилища
const removeExpirationDate = () => {
  localStorage.removeItem('expired');
};

// Получить refresh-Token
const getRefreshToken = () => localStorage.getItem('refreshToken');

// Сохранить refresh-Token
const setRefreshToken = token => {
  localStorage.setItem('refreshToken', token);
};

// Удалить refresh-Token
const removeRefreshToken = () => {
  localStorage.removeItem('refreshToken');
};

// Сохранить Имя пользователя
const setUserName = token => {
  localStorage.setItem('name', token);
};

// Получить Имя пользователя
const getUserName = () => localStorage.getItem('name');

// Удалить Имя пользователя
const removeUserName = () => {
  localStorage.removeItem('name');
};

// Авторизация
const login = async code => {
  if (!code) return;

  try {
    const { ok, status, response } = await fetchData({
      url: `${AUTH_URL}/token`,
      headers: {
        Accept: 'application/json, text/plain',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      data: `code=${code}`,
      stringify: false,
    });
    const { access_token, refresh_token, expires_in, user } = response;

    if (!ok || !access_token || !refresh_token) {
      throw { status, response };
    }

    setJwtToken(access_token);
    setJwtTokenLifeTime(expires_in);
    setExpirationDate(expires_in);
    setRefreshToken(refresh_token);
    setUserName(user.name);

    return { ok: true, accessToken: access_token, refreshToken: refresh_token };
  } catch (error) {
    console.error('Ошибка при обновление jwt-токена', error);

    return {
      ok: false,
      status: error?.status,
      response: error?.response,
      msg: 'Ошибка при обновление jwt токена',
      error,
    };
  }
};

const logout = () => {
  revokeJwtToken();
  removeJwtToken();
  removeJwtTokenLifeTime();
  removeExpirationDate();
  removeRefreshToken();
  removeUserName();
  location.reload();
};

const tokenValidation = () => getExpirationDate() > new Date().getTime();

/**
 * Запрос на обновление jwt токена, при успехе записывает токен и параметры в хранилище, так же возвращает статус запроса в форме объекта
 */
const refreshJwtToken = async () => {
  const refreshToken = getRefreshToken();

  if (!refreshToken || refreshToken === 'undefined') {
    return {
      ok: false,
      msg: 'Отсутствует сохраненный Refresh-Token',
    };
  }

  try {
    const { ok, status, response } = await fetchData({
      url: `${AUTH_URL}/refresh`,
      headers: {
        Accept: 'application/json, text/plain',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      data: `client_id=${CLIENT_ID}&grant_type=${'refresh_token'}&refresh_token=${refreshToken}`,
      stringify: false,
    });

    const { access_token, refresh_token, expires_in } = response;

    if (!ok || !access_token || !refresh_token || !expires_in) {
      throw { status, response };
    }

    setJwtToken(access_token);
    setJwtTokenLifeTime(expires_in);
    setExpirationDate(expires_in);
    setRefreshToken(refresh_token);

    return {
      ok: true,
      status,
      response,
    };
  } catch (error) {
    console.error('Ошибка при обновление jwt-токена', error);

    return {
      ok: false,
      status: error?.status,
      response: error?.response,
      msg: 'Ошибка при обновление jwt токена',
      error,
    };
  }
};

/**
 * Переадресация на сервис авторизации
 * @param {String} redirectUri Адрес для обратного редиректа
 */
const redirectToAuthService = (redirectUri = '') => {
  const redirectUrl = new URL(AUTH_URL);
  redirectUrl.searchParams.set('client_id', CLIENT_ID);
  redirectUrl.searchParams.set('redirect_uri', location.origin + redirectUri);
  redirectUrl.searchParams.set('state', 'asd3ce3ec23e');
  window.location.href = redirectUrl;
};

export {
  removeJwtToken,
  getJwtToken,
  refreshJwtToken,
  getExpirationDate,
  getUserName,
  getRefreshToken,
  removeRefreshToken,
  getJwtTokenLifeTime,
  login,
  logout,
  redirectToAuthService,
  tokenValidation,
};
