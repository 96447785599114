<template>
  <section class="kv-page">
    <bn-table
      :rows="banks"
      :columns="tableHeaders"
      :loading="loading"
      :error="tableError"
    >
      <template #cell(status)="{ data: { row } }">
        <span
          class="status-icon"
          :title="row.is_approved ? 'Сверено' : 'Требует сверки'"
          :class="{ 'status-icon--approved': row.is_approved }"
        ></span>
      </template>

      <template #cell(bank)="{ data: { row } }">
        <router-link
          :to="{
            name: 'StmtsPage',
            query: {
              filter: 'banks',
              uuid: row.bank_uuid,
              value: getBankNameByUuid(row.bank_uuid),
            },
          }"
          target="_blank"
        >
          {{ getBankNameByUuid(row.bank_uuid) }}
        </router-link>
      </template>

      <template #cell(kv_sum)="{ data: { row } }">
        <template v-if="!row?.calculating_status?.status">
          {{ toCurrency(row.kv_sum) }}
        </template>
        <template
          v-else
          :title="`Перерасчет начат: ${formattedDate(
            row?.calculating_status?.updated_at,
          )}  ${formattedTime(row?.calculating_status?.updated_at)}`"
        >
          <bn-preloader-for-button width="30px" />
        </template>
      </template>

      <template #cell(recalculate)="{ data: { row } }">
        <template v-if="!row.is_approved">
          <bn-button
            color="info"
            :disabled="loading"
            @click="recalculate(row.bank_uuid)"
          >
            Пересчитать</bn-button
          >
        </template>
        <template v-else>Сверено</template>
      </template>

      <template #cell(actions)="{ data: { row } }">
        <div v-if="!row?.calculating_status?.status" class="flex items-center">
          <template v-if="row.is_approved">
            <div class="icon-approved" :title="row.user_uuid">
              <bn-icon icon="check" />
            </div>
            <span class="mr-1">{{ formattedDate(row.updated_at) }}</span>
            <span>{{ formattedTime(row.updated_at) }}</span>
          </template>
          <bn-button
            v-else
            @click="verify(row.bank_uuid)"
            color="success"
            :disabled="loading && row.bank_uuid === currentBankUuid"
            >Сверить</bn-button
          >
        </div>
        <div v-else>Идет перерасчёт..</div>
      </template>
    </bn-table>

    <bn-kv-table-right-panel
      :localized-current-date="localizedDate"
      :normalized-current-date="normalizedDate"
      :total="total"
    />
    <bn-modal
      title="Утвердить сверку?"
      class="bn-overlay--light"
      ref="verifyModal"
    >
      <template #footer="{ confirm, close }">
        <bn-button @click="confirm" color="success">Сверить</bn-button>
        <bn-button @click="close" color="warning">Отменить</bn-button></template
      >
    </bn-modal>
  </section>
</template>
<script>
import BnTable from '../BnTable/BnTable.vue';
import KvTableConfig from './kv-table-config.json';
import BnKvTableRightPanel from './BnKvTableRightPanel.vue';
import BnPreloaderForButton from '../ui/BnPreloaderForButton.vue';
import {
  formattedDate,
  formattedTime,
  toCurrency,
  normalizedDateFromObj,
  localizedDateFromObj,
} from '../../helpers/index';
import { getKvTableData, recalculateBank, verifyBank } from './api-kv';

export default {
  components: {
    BnTable,
    BnKvTableRightPanel,
    BnPreloaderForButton,
  },
  ajax: null,
  abortController: null,

  data() {
    return {
      tableHeaders: KvTableConfig.data,
      banks: [],
      total: {
        provisionSum: null,
        finalTariff: null,
        kv: null,
        stmts: null,
        banks: null,
        approvedBanks: null,
      },
      currentBankUuid: null,

      currentPeriod: {
        month: null,
        year: null,
      },

      loading: false,

      tableError: null,
      error: [],
      success: [],
    };
  },
  async mounted() {
    if (this.isLoggedIn) this.getTableData();
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
    banksDictionary() {
      return this.$store.state.dictionaries.banks;
    },
    period() {
      return this.$store.state.filter.period;
    },
    normalizedDate() {
      return normalizedDateFromObj(this.currentPeriod);
    },
    localizedDate() {
      return localizedDateFromObj(this.currentPeriod);
    },
  },
  methods: {
    async recalculate(uuid) {
      this.loading = true;
      const { ok, data, msg } = await recalculateBank({
        uuid,
        period: this.period,
      });
      this.loading = false;

      if (!ok) {
        this.error.unshift(msg);
        return;
      }

      this.banks.forEach(bank => {
        if (bank.bank_uuid === data.bank_uuid) Object.assign(bank, data);
      });
      this.success.unshift(
        `Банк ${this.getBankNameByUuid(data.bank_uuid)} - начат перерасчёт`,
      );
    },
    async getTableData() {
      this.loading = true;

      const { ok, data, msg } = await getKvTableData(this.period);

      this.loading = false;

      if (!ok) {
        this.error.unshift(msg);
        return;
      }
      this.currentPeriod = {
        month: data.month,
        year: data.year,
      };

      this.total.provisionSum = data.provision_sum;
      this.total.finalTariff = data.final_tariff;
      this.total.kv = data.kv_sum;
      this.total.stmts = data.total_stmts;
      this.total.banks = data.total_banks;
      this.total.approvedBanks = data.total_approved_banks;

      this.banks = data.banks;
    },
    async verify(uuid) {
      this.currentBankUuid = uuid;

      const modalConfirmed = await this.$refs.verifyModal.open();
      if (!modalConfirmed) return;

      this.loading = true;

      const { ok, data, msg } = await verifyBank({
        uuid,
        period: this.period,
      });

      this.loading = false;

      if (!ok) {
        this.error.unshift(msg);
        return;
      }

      this.banks.forEach(bank => {
        if (bank.bank_uuid === data.bank_uuid) Object.assign(bank, data);
      });
      this.success.unshift('Данные успешно сохранены');
    },
    getBankNameByUuid(uuid) {
      const name = this.banksDictionary[uuid];

      return name ? name : uuid;
    },
    toCurrency,
    formattedDate,
    formattedTime,
  },
  watch: {
    async isLoggedIn(isLogged) {
      if (!isLogged) return;

      this.getTableData();
    },
    period() {
      this.getTableData();
    },
    error: {
      deep: true,
      handler: function () {
        this.$swal({
          icon: 'error',
          title: this.error[0],
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 3000,
        });
      },
    },
    success: {
      deep: true,
      handler: function () {
        this.$swal({
          icon: 'success',
          title: this.success[0],
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 3000,
        });
      },
    },
  },
};
</script>
<style lang="scss">
.kv-page {
  position: fixed;
  top: 60px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  background-color: #fff;
  font-size: 0.8rem;

  .n-table {
    flex: 1;
    width: calc(100% - 300px);
  }
  .bn-table__right-panel {
    width: 300px;
    padding: 30px;
    position: relative;
    z-index: 50;
    box-shadow: $shadow-light;
  }
}
.icon-approved {
  width: 18px;
  height: 18px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-right: 10px;
  border: 1px solid $color-success;

  svg {
    fill: $color-success;
  }
}
</style>
