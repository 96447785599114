<template>
  <bn-kv-table />
</template>
<script>
import BnKvTable from '../components/BnKvTable/BnKvTable.vue';

export default {
  components: { BnKvTable },
};
</script>
