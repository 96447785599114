import { createStore } from 'vuex';
import {
  login,
  refreshJwtToken,
  getJwtTokenLifeTime,
  removeRefreshToken,
} from '../services/authentication-service';
import { getDictionaries } from '../services/api';
import { sleep } from '../helpers/index';

export const store = createStore({
  state() {
    return {
      isLoggedIn: false,
      connectionStatus: '',

      dictionaries: {},

      filter: {
        agents: {},
        banks: {},
        number: null,
        period: null,
      },

      alertBox: [],
    };
  },
  mutations: {
    SET_DICTIONARIES: (state, dictionaries) => {
      state.dictionaries = dictionaries;
    },
    SET_FILTER: (state, { name, value }) => {
      state.filter[name] = value;
    },
    RESTORE_FILTER: (state, filter) => {
      state.filter = filter;
    },
    CLEAR_FILTER: state => {
      state.filter = {
        agents: {},
        banks: {},
        number: null,
        period: null,
      };
    },
    AUTH_SUCCESSFUL: state => {
      state.isLoggedIn = true;
    },
    AUTH_FAILED: state => {
      state.isLoggedIn = false;
    },
    SET_CONNECTION_STATUS: (state, status) => {
      state.connectionStatus = status;
    },
    ADD_ALERT: (state, alert) => {
      state.alertBox.unshift(alert);
    },
  },
  actions: {
    async GET_DICTIONARIES({ commit }) {
      const { ok, dictionaries } = await getDictionaries();

      if (!ok) {
        commit('ADD_ALERT', {
          text: 'Ошибка при получении списка банков',
          type: 'error',
        });
        this.loading = false;
        return;
      }

      commit('SET_DICTIONARIES', dictionaries);
    },
    SET_FILTER({ commit, state }, filter) {
      commit('SET_FILTER', filter);
      localStorage.setItem('filter', JSON.stringify(state.filter));
    },
    RESTORE_FILTER({ commit }) {
      const filter = localStorage.filter;

      if (filter) commit('RESTORE_FILTER', JSON.parse(filter));
    },
    CLEAR_FILTER({ commit, state }, filterList) {
      if (!filterList) {
        commit('CLEAR_FILTER');
        localStorage.removeItem('filter');
        return;
      }

      filterList.forEach(filter => {
        let value;

        if (filter === 'agents' || filter === 'banks') value = {};
        if (filter === 'number' || filter === 'period') value = null;

        commit('SET_FILTER', { name: filter, value });
      });
      localStorage.setItem('filter', JSON.stringify(state.filter));
    },
    ADD_ALERT({ commit }, alert) {
      commit('ADD_ALERT', alert);
    },
    LOGIN: async ({ commit }, { code }) => {
      const { ok, status } = await login(code);

      if (status === 403) {
        commit('AUTH_FAILED');
        commit('SET_CONNECTION_STATUS', 'invalid refresh token');
        return { ok, status };
      }

      if (!ok) {
        commit('AUTH_FAILED');
        commit('SET_CONNECTION_STATUS', 'authentication error');
        return { ok, status };
      }

      commit('AUTH_SUCCESSFUL');
      commit('SET_CONNECTION_STATUS', 'success');

      return { ok, status };
    },
    REFRESH_TOKEN: async () => {
      const { ok, status, response, msg } = await refreshJwtToken();

      if (status === 401) {
        store.commit('AUTH_FAILED');
        store.commit('SET_CONNECTION_STATUS', 'invalid jwt token');
      }
      if (status === 403 || status === 405) {
        removeRefreshToken();
        store.commit('AUTH_FAILED');
        store.commit('SET_CONNECTION_STATUS', 'invalid refresh token');
      }
      if (!ok) {
        store.commit('AUTH_FAILED');
        store.commit('SET_CONNECTION_STATUS', 'network error');
        return { ok, status, response, msg };
      }

      store.commit('AUTH_SUCCESSFUL');
      store.commit('SET_CONNECTION_STATUS', 'success');

      sleep(getJwtTokenLifeTime()).then(async () => {
        await store.dispatch('REFRESH_TOKEN');
      });

      return { ok, status, response, msg };
    },
  },
});
