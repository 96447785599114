<template>
  <div class="filter-modal">
    <div class="filter-search pb-2">
      <input v-model="filter" placeholder="Найти..." type="text" />
    </div>

    <div class="checked-list">
      <template v-for="(bank, key) in selected" :key="key">
        <bn-button
          :disabled="loading"
          icon="close"
          color="success"
          @click="uncheckBank(key)"
          >{{ bank.name }}
        </bn-button>
      </template>
    </div>

    <div class="checkbox-list">
      <div class="checkbox-list__item" v-if="filteredBanks.length === 0">
        Нет подходящих элементов
      </div>
      <template v-for="(bank, key) in filteredBanks" :key="key">
        <div v-show="!bank.checked" class="checkbox-list__item">
          <input
            type="checkbox"
            :disabled="loading"
            :checked="bank.checked"
            :id="key"
            @change="banks[key].checked = !banks[key].checked"
          />
          <label :for="key">{{ bank.name }}</label>
        </div>
      </template>
    </div>
    <div class="flex justify-between items-center pt-2">
      <bn-button
        @click="clearCheckedList"
        :disabled="loading"
        class="checkbox-list__clear"
        >Очистить
      </bn-button>
      <bn-button
        @click="confirmCheckedList"
        :disabled="loading"
        icon="filter"
        color="success"
        >Применить
      </bn-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    banksList: {
      type: Object,
      required: false,
      default: () => {},
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['close'],
  data() {
    return {
      filter: '',
      banks: {},
    };
  },
  computed: {
    selectedBanks() {
      return this.$store.state.filter.banks;
    },

    selected() {
      const selected = {};

      Object.keys(this.banks).forEach(uuid => {
        const isChecked = this.banks[uuid].checked;

        if (isChecked) selected[uuid] = this.banks[uuid];
      });
      return selected;
    },

    filteredBanks() {
      const filtered = {};

      Object.keys(this.banks).forEach(uuid => {
        const name = this.banks[uuid].name;

        if (!name) return;

        const isValid = name.toUpperCase().includes(this.filter.toUpperCase());

        if (isValid) filtered[uuid] = this.banks[uuid];
      });
      return filtered;
    },
  },
  mounted() {
    Object.keys(this.banksList).forEach(uuid => {
      this.banks[uuid] = {
        name: this.banksList[uuid],
        checked: false,
      };
    });
    Object.keys(this.selectedBanks).forEach(uuid => {
      this.banks[uuid].checked = true;
    });
  },
  methods: {
    uncheckBank(key) {
      this.banks[key].checked = !this.banks[key].checked;
      this.setFilter();
    },
    confirmCheckedList() {
      this.setFilter();
      this.$emit('close');
    },
    clearCheckedList() {
      Object.keys(this.banks).forEach(uuid => {
        this.banks[uuid].checked = false;
      });
      this.setFilter();
    },
    setFilter() {
      this.$store.dispatch('SET_FILTER', {
        name: 'banks',
        value: this.selected,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-modal {
  width: 280px;
}
.filter-search {
  input {
    width: 100%;
  }
}
.checked-list {
  button {
    font-size: 0.8rem;
    height: auto;
    padding: 4px 12px 4px 4px;
    margin: 0 3px 3px 0;
  }
}
.checkbox-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  margin: 5px -15px;
  padding: 5px 10px;
  box-shadow: inset -4px -4px 3px 0px rgba(50, 50, 50, 0.05),
    inset -2px 4px 4px 0px rgba(50, 50, 50, 0.05);

  &__item {
    padding: 5px 5px 5px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;

    input {
      margin-right: 5px;
      cursor: pointer;
    }
    label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }
  }
  &__clear {
    border: 0;
    text-decoration: underline;

    &:hover,
    &:focus {
      background-color: #fff;
    }
  }
}
</style>
