<template>
  <div
    v-if="config?.open"
    class="context-menu"
    :style="{
      left: config ? `${config.coordX}px` : 'unset',
      top: config ? `${config.coordY}px` : '30%',
    }"
    :data-is-open="isOpen"
  >
    <bn-button icon="content-copy" @click="$emit('copy-msg', config.msgUuid)">
      Копировать
    </bn-button>
    <bn-button
      v-if="config.can.update"
      icon="pencil-outline"
      @click="$emit('edit-msg', config.msgUuid)"
      >Редактировать
    </bn-button>
    <bn-button
      v-if="config.can.delete"
      icon="delete"
      @click="$emit('delete-msg', config.msgUuid)"
      >Удалить
    </bn-button>
  </div>
</template>

<script>
export default {
  props: {
    config: {
      BnButtontype: Object,
      required: false,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    edit() {
      console.log('edit');
    },
  },
};
</script>

<style lang="scss" scoped>
.context-menu {
  position: fixed;
  z-index: 1000;
  background-color: #fff;
  border-radius: $border-radius;
  box-shadow: $shadow-base;
  width: 150px;
  padding: 5px;

  button {
    width: 100%;
    margin-bottom: 4px;
    border: 0;
  }
  button:last-child {
    margin-bottom: 0;
  }
}
</style>
