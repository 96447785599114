<template>
  <div class="bn-table__panel">
    <bn-button
      :color="settingsChanged ? 'warning' : 'info'"
      square
      icon="file-cog-outline"
      @click.self="$emit('settings-open', $event.target)"
      title="Открыть настройки столбцов"
    />
    <bn-button
      @click="$emit('clear-filter')"
      :color="filterIsActive ? 'warning' : 'info'"
      square
      icon="filter-remove"
      title="Очистить фильтр"
      class="mr-4"
    />
    <bn-button
      @click="$emit('create-stmt')"
      icon="file-document-plus-outline"
      color="success"
      title="Создать заявку"
    >
      Создать заявку
    </bn-button>
    <div class="bn-table__panel-right">
      <span v-if="period"
        >Выбранный период: <b>{{ period }}</b>
      </span>
      <bn-button @click="$emit('get-report')" color="warning">
        Скачать отчет
      </bn-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    settingsChanged: { type: Boolean, required: false },
    filterIsActive: { type: Boolean, required: false },
  },
  data() {
    return {};
  },
  computed: {
    period() {
      return this.$store.state.filter.period;
    },
  },
};
</script>

<style lang="scss">
.bn-table__panel {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  background-color: #dfdfdf;

  button {
    margin-left: 10px;
  }
  &-search {
    padding: 0px 30px;

    input {
      margin-left: 10px;
      min-width: 300px;
    }
  }
  &-right {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
}
</style>
