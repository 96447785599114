<template>
  <section class="stmts-page">
    <bn-table-top-panel
      :settings-changed="colSettingsChanged"
      :filter-is-active="filterIsActive"
      @settings-open="openColSettings"
      @clear-filter="clearFilter"
      @get-report="getReport"
      @create-stmt="openCreateStmtModal"
    />

    <bn-table
      :class="{
        'n-table--blocked': changedStmtUuid,
      }"
      :rows="tableData"
      :columns="tableHeaders"
      :loading="loading"
      :error="tableError"
      :row-class-name="tableRowClassName"
      :applied-filters="appliedFilters"
      @dbl-click-body-cell="openCellModal"
      @open-menu="openFilter"
      v-model:sortBy="sortBy"
    >
      <template #cell(status)="{ data: { row } }">
        <span
          class="status-icon"
          :title="row.period_kv.is_approved ? 'Сверено' : 'Требует сверки'"
          :class="{
            'status-icon--approved': row.period_kv.is_approved,
          }"
        ></span>
        <span
          class="status-icon"
          :title="row.is_manual ? 'Задано вручную' : 'Рассчитано автоматически'"
          :class="{ 'status-icon--manual': row.is_manual }"
        ></span>
        <span
          class="status-icon"
          :title="row.is_bn24 ? 'Заявка с БН24' : 'Заявка создана вручную'"
          :class="{ 'status-icon--manual': !row.is_bn24 }"
        ></span>
      </template>

      <template #cell(paid_dt)="{ data: { row } }">
        <span>{{
          formattedDate(
            row.uuid === changedStmtUuid && changedStmtParams.paid_dt
              ? changedStmtParams.paid_dt
              : row.paid_dt,
          )
        }}</span>
      </template>

      <template #cell(client)="{ data: { row } }">
        <span :title="row.client.name">{{ row.client.name }}</span>
        <span>{{ row.client.inn }}</span>
      </template>

      <template #cell(bank_uuid)="{ data: { row } }">
        <span
          class="text-button color-success"
          @click="
            filterBy('banks', {
              [row.bank_uuid]: getBankNameByUuid(row.bank_uuid),
            })
          "
          :title="getBankNameByUuid(row.bank_uuid)"
        >
          {{ getBankNameByUuid(row.bank_uuid) }}
        </span>
      </template>

      <template #cell(auction_number)="{ data: { row } }">
        <span
          :title="`${getLawNameByUuid(row.law)}, ${getProductNameByUuid(
            row.product.uuid,
          )} (${getProductTypeByUuid(row.product.uuid, row.product.type)})`"
          >{{
            `${getLawNameByUuid(row.law)}, 
             ${getProductNameByUuid(row.product.uuid)} (
             ${getProductTypeByUuid(row.product.uuid, row.product.type)})`
          }}
        </span>
        <span :title="row.auction_number"> {{ row.auction_number }}</span>
      </template>

      <template #cell(provision_sum)="{ data: { row } }">
        <span>{{
          toCurrency(
            row.uuid === changedStmtUuid && changedStmtParams.provision_sum
              ? changedStmtParams.provision_sum
              : row.provision_sum,
          )
        }}</span>
      </template>

      <template #cell(base_tariff)="{ data: { row } }">
        <span>{{
          toCurrency(
            row.uuid === changedStmtUuid && changedStmtParams.base_tariff
              ? changedStmtParams.base_tariff
              : row.base_tariff,
          )
        }}</span>
      </template>

      <template #cell(final_tariff)="{ data: { row } }">
        <span>{{
          toCurrency(
            row.uuid === changedStmtUuid && changedStmtParams.final_tariff
              ? changedStmtParams.final_tariff
              : row.final_tariff,
          )
        }}</span>
      </template>

      <template #cell(agent_commission)="{ data: { row } }">
        <span>{{
          row.agent?.commission >= 0
            ? toCurrency(
                row.uuid === changedStmtUuid &&
                  changedStmtParams['agent.commission']
                  ? changedStmtParams['agent.commission']
                  : row.agent.commission,
              )
            : '-'
        }}</span>
      </template>

      <template #cell(manager_commission)="{ data: { row } }">
        <span>{{
          row.manager?.commission >= 0
            ? toCurrency(
                row.uuid === changedStmtUuid &&
                  changedStmtParams['manager.commission']
                  ? changedStmtParams['manager.commission']
                  : row.manager.commission,
              )
            : '-'
        }}</span>
      </template>

      <template #cell(agent_name)="{ data: { row } }">
        <span
          v-if="row.agent"
          @click="filterBy('agents', { [row.agent.uuid]: row.agent.name })"
          :title="row.agent ? row.agent.name : '-'"
          class="text-button color-success"
        >
          {{ row.agent.name }}
        </span>
        <span v-else>-</span>
      </template>

      <template #cell(manager_name)="{ data: { row } }">
        <span :title="row.manager ? row.manager.name : '-'">
          {{ row.manager ? row.manager.name : '-' }}
        </span>
      </template>

      <template #cell(av_percent)="{ data: { row } }">
        <span> {{ row.av?.percent ? toCurrency(row.av.percent) : '-' }}</span>
      </template>

      <template #cell(av_sum)="{ data: { row } }">
        <span>{{
          row.av?.sum
            ? toCurrency(
                row.uuid === changedStmtUuid && changedStmtParams['av.sum']
                  ? changedStmtParams['av.sum']
                  : row.av.sum,
              )
            : '-'
        }}</span>
      </template>

      <template #cell(kv_percent)="{ data: { row } }">
        {{ row.kv?.percent ? toCurrency(row.kv.percent) : '-' }}
      </template>

      <template #cell(kv_sum)="{ data: { row } }">
        {{
          row.kv?.sum
            ? toCurrency(
                row.uuid === changedStmtUuid && changedStmtParams['kv.sum']
                  ? changedStmtParams['kv.sum']
                  : row.kv.sum,
              )
            : '-'
        }}
      </template>

      <template #cell(diff_kv_av_percent)="{ data: { row } }">
        {{ toCurrency(((row.diff_kv_av / row.final_tariff) * 100).toFixed(2)) }}
      </template>

      <template #row(end)="{ data: { row } }">
        <div
          v-if="changedStmtUuid === row.uuid"
          :style="{
            top: `${changedRowCoords.coordY}px`,
          }"
          class="n-table__row-actions"
        >
          <bn-button-group>
            <bn-button
              @click="saveChanges({ recalculate: true })"
              color="success"
              >Сохранить с перерасчётом
            </bn-button>
            <template #dropdown>
              <bn-button
                @click="saveChanges({ recalculate: false })"
                color="success"
                >Сохранить без перерасчётa
              </bn-button>
            </template>
          </bn-button-group>
          <bn-button @click="cancelChanges" color="danger">Отменить</bn-button>
        </div>
      </template>
    </bn-table>

    <bn-pagination
      :currentPage="page"
      :total-pages="totalPages"
      :total-items="totalItems"
      :items-on-page="itemsOnPage"
      :items-on-page-choice="itemsOnPageСhoice"
      @page-changed="pageChanged"
      @select-changed="itemsOnPageChanged"
    />

    <bn-modal
      class="bn-overlay--light"
      ref="changeСellModal"
      :show-header="false"
      :show-footer="false"
      :click-outside="false"
      :modal-coords="changedCellCoords"
    >
      <edit-cell
        :stmt-key="openForChangeStmtKey"
        :stmt-value="stmtValueByKey"
        :input-type="inputType"
        @confirm="confirmChanges"
        @close="$refs.changeСellModal.close()"
      />
    </bn-modal>

    <bn-modal
      class="bn-overlay--light"
      ref="modal"
      :show-header="false"
      :show-footer="false"
      :modal-coords="modalCoords"
    >
      <template v-if="openedModalName === 'colSettings'">
        <bn-button @click="showAllCol" color="success">Выбрать все</bn-button>

        <div class="colSettings">
          <div
            v-for="(item, idx) in tableHeaders"
            :key="idx"
            :data-col-idx="idx"
            class="colSettings__item"
          >
            <template v-if="idx !== 0">
              <input
                @click="changeСolActivity(idx)"
                type="checkbox"
                :checked="item.show"
                :id="`colSettings__item-${idx}`"
              />
              <label :for="`colSettings__item-${idx}`">{{ item.title }}</label>
            </template>
          </div>
        </div>
      </template>
      <banks-filter
        v-if="openedModalName === 'bank_uuid'"
        :banks-list="banksDictionary"
        :loading="loading"
        @close="$refs.modal.close()"
      />
      <agents-filter
        v-if="openedModalName === 'agent_name'"
        :loading="loading"
        @close="$refs.modal.close()"
      />
      <date-filter v-if="openedModalName === 'paid_dt'" :loading="loading" />
      <number-filter
        v-if="openedModalName === 'number'"
        :loading="loading"
        @close="$refs.modal.close()"
      />
    </bn-modal>

    <bn-modal
      title="Создание заявки"
      class="bn-overlay--light"
      ref="createStmtModal"
      :click-outside="false"
      :show-footer="false"
    >
      <bn-create-stmt
        v-if="createStmtModalOpen"
        @close="closeCreateStmtModal"
      />
    </bn-modal>
  </section>
</template>

<script>
import BnTable from '../BnTable/BnTable.vue';
import BnTableTopPanel from './BnTableTopPanel.vue';
import EditCell from './BnTableEditCell.vue';
import BanksFilter from './Filters/BnTableBanksFilter.vue';
import AgentsFilter from './Filters/BnTableAgentsFilter.vue';
import DateFilter from './Filters/BnTableDateFilter.vue';
import NumberFilter from './Filters/BnTableStmtNumberFilter.vue';
import BnPagination from './BnPagination.vue';
import BnCreateStmt from '../BnCreateStmt/BnCreateStmt.vue';

import tableConfig from './table-config.json';
import { toCurrency, toNumber, formattedDate } from '../../helpers/index';
import { tokenValidation } from '../../services/authentication-service';
import { getStmtsTableData, getReport, saveStmtChanges } from './api-stmts';

export default {
  components: {
    BnTable,
    BnTableTopPanel,
    EditCell,
    BanksFilter,
    AgentsFilter,
    DateFilter,
    NumberFilter,
    BnPagination,
    BnCreateStmt,
  },
  data() {
    return {
      tableHeaders: tableConfig.data,

      showAllColumns: false,

      inputType: null,
      openForChangeStmtKey: null,
      changedStmtUuid: null,
      changedStmt: {},
      changedStmtParams: {},

      openedModalName: null,

      modalCoords: { coordX: null, coordY: null },
      changedCellCoords: { coordX: null, coordY: null },
      changedRowCoords: { coordX: null, coordY: null },

      sortBy: {
        type: null,
        name: null,
      },
      tableData: [],

      page: 1,
      itemsOnPage: tableConfig.itemsOnPage,
      itemsOnPageСhoice: tableConfig.itemsOnPageСhoice,
      totalItems: null,
      totalPages: null,

      colSettingsChanged: false,

      createStmtModalOpen: false,

      loading: false,
      tableError: null,
    };
  },

  async mounted() {
    this.restoreSavedSettings();

    if (this.$route.query.filter) {
      this.filterBy(this.$route.query.filter, {
        [this.$route.query.uuid]: this.$route.query.value,
      });
      this.$router.replace({ query: {} });

      return;
    }

    if (this.isLoggedIn) this.getTableData();
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
    filter() {
      return this.$store.state.filter;
    },
    banksDictionary() {
      return this.$store.state.dictionaries.banks;
    },
    lawsDictionary() {
      return this.$store.state.dictionaries.laws;
    },
    productsDictionary() {
      return this.$store.state.dictionaries.products;
    },
    filterIsActive() {
      return !!(
        this.sortBy.type ||
        this.filter.period ||
        this.filter.number ||
        Object.keys(this.filter.banks).length > 0 ||
        Object.keys(this.filter.agents).length > 0
      );
    },
    connectionStatus() {
      return this.$store.state.connectionStatus;
    },
    stmtValueByKey() {
      const name = this.openForChangeStmtKey;

      if (!name) return null;

      const path = this.getColumnByName(name).path;

      if (!path) return null;

      const pathArray = path.split('.');

      return pathArray.reduce(
        (prev, current) => prev[current],
        this.changedStmt,
      );
    },
    // Ширина таблицы рассчитанная на основе ширины столбцов из конфига таблицы
    tableWidth() {
      return this.tableHeaders.reduce(
        (previous, current) => previous + current.width,
        0,
      );
    },
    appliedFilters() {
      let appliedFilters = [];
      if (Object.keys(this.filter.banks).length > 0) {
        appliedFilters.push('bank_uuid');
      }
      if (Object.keys(this.filter.agents).length > 0) {
        appliedFilters.push('agent_name');
      }
      if (this.filter.period) appliedFilters.push('paid_dt');
      if (this.filter.number) appliedFilters.push('number');

      return appliedFilters;
    },
    stmtsSearchParams() {
      const filterRequests = this.filterRequestUrl
        ? '&' + this.filterRequestUrl
        : '';
      return `items_per_page=${this.itemsOnPage}&page_index=${this.page}${filterRequests}`;
    },
    filterRequestUrl() {
      let requestsArray = [];

      if (this.filter.period) {
        requestsArray.push(`month_paid=${this.filter.period}`);
      }

      Object.keys(this.filter.banks).forEach(uuid => {
        requestsArray.push(`bank_uuid=${uuid}`);
      });

      Object.keys(this.filter.agents).forEach(uuid => {
        requestsArray.push(`agent_uuid=${uuid}`);
      });

      if (this.sortBy.type) {
        requestsArray.push(`order_by=${this.sortBy.name}`);
        requestsArray.push(`order_by=${this.sortBy.type}`);
      }
      if (this.filter.number) {
        requestsArray.push(`stmt_number=${this.filter.number}`);
      }

      return requestsArray.reduce((result, current, i) => {
        return i === 0 ? (result = current) : `${result}&${current}`;
      }, '');
    },
  },
  watch: {
    async isLoggedIn(isLogged) {
      if (!isLogged) return;

      this.getTableData();
    },

    tableHeaders: {
      deep: true,
      handler: function () {
        const disabledCols = JSON.parse(localStorage.getItem('disabled-cols'));

        if (disabledCols === null) {
          this.colSettingsChanged = false;
          return;
        }

        this.colSettingsChanged = disabledCols.length > 0;
      },
    },

    filter: {
      deep: true,
      handler: function () {
        this.getTableData();
      },
    },

    sortBy: {
      deep: true,
      handler: function () {
        localStorage.setItem('sortBy', JSON.stringify(this.sortBy));

        this.getTableData();
      },
    },

    totalPages() {
      // Если выбранная страница больше общего кол-ва страниц,
      // открываем последнюю страницу
      if (this.page > this.totalPages && this.totalPages > 0) {
        this.page = this.totalPages;
        this.getTableData();
      }
    },
  },
  methods: {
    tableRowClassName({ row }) {
      if (row.uuid === this.changedStmtUuid) return 'n-table__row--changed';

      return '';
    },
    async restoreSavedSettings() {
      const sortBy = localStorage.sortBy;
      const disabledCols = JSON.parse(localStorage.getItem('disabled-cols'));

      if (sortBy) this.sortBy = JSON.parse(sortBy);

      if (disabledCols)
        disabledCols.forEach(item => (this.tableHeaders[item].show = false));
    },
    async getReport() {
      this.loading = true;
      const { ok, msg } = await getReport(this.filterRequestUrl);

      if (!ok) {
        this.$store.dispatch('ADD_ALERT', {
          text: msg,
          type: 'error',
        });
      }

      this.loading = false;
    },
    async getTableData() {
      this.loading = true;
      const isValid = tokenValidation();

      if (!isValid) {
        await this.$store.dispatch('REFRESH_TOKEN');
      }

      if (!this.isLoggedIn) return;
      const { ok, data, status, msg } = await getStmtsTableData(
        this.stmtsSearchParams,
      );

      if (status === 401) {
        await this.$store.dispatch('REFRESH_TOKEN');

        await this.getTableData();
      }
      if (status === 403 || status === 405) {
        this.$store.commit('AUTH_FAILED');
        this.$store.commit('SET_CONNECTION_STATUS', 'invalid refresh token');
        return;
      }
      if (!ok) {
        this.$store.dispatch('ADD_ALERT', {
          text: msg,
          type: 'error',
        });
        this.loading = false;
        return;
      }

      this.tableData = data.items;
      this.itemsOnPage = data.items_per_page;
      this.page = data.page_index;
      this.totalItems = data.total_items;
      this.totalPages = data.total_pages;

      this.loading = false;
    },
    async saveChanges({ recalculate }) {
      this.loading = true;

      const { ok, stmt, msg } = await saveStmtChanges({
        uuid: this.changedStmtUuid,
        recalculate,
        stmt: this.changedStmt,
        stmtParams: this.changedStmtParams,
      });

      if (!ok) {
        this.$store.dispatch('ADD_ALERT', {
          text: msg,
          type: 'error',
        });
        this.loading = false;
        return;
      }

      this.changeStmt(stmt);
      this.clearСhangesStmt();

      this.$swal({
        icon: 'success',
        title: 'Данные успешно сохранены',
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
      });
      this.loading = false;
    },

    async openCellModal({ e, row, col: { name, editable } }) {
      if (
        !editable ||
        //если есть измененная заявка, то мы можем изменять только ее
        (!!this.changedStmtUuid && !(row.uuid === this.changedStmtUuid)) ||
        // Если не задан агент или менеджер, то запрет на изменение соответствующего поля
        (name === 'manager.commission' && !row.manager) ||
        (name === 'agent.commission' && !row.agent)
      )
        return;

      if (row.period_kv.is_approved) {
        this.$store.dispatch('ADD_ALERT', {
          text: 'Изменения запрещены, данный период сверен',
          type: 'error',
        });
        return;
      }

      const currentCol = e.target.closest('.n-table__cell');
      const currentRow = e.target.closest('.n-table__row');

      this.openForChangeStmtKey = name;
      this.changedStmt = row;
      this.inputType = this.tableHeaders.find(
        item => item.name === name,
      ).inputType;

      //Если окно изменения ячейки выходит за экран, то left считаем
      //ширина экрана - ширина модального окна "370"
      this.changedCellCoords.coordX =
        currentCol.getBoundingClientRect().left + 370 > window.innerWidth
          ? window.innerWidth - 370
          : currentCol.getBoundingClientRect().left;
      this.changedCellCoords.coordY = currentCol.getBoundingClientRect().top;

      this.changedRowCoords.coordY = currentRow.getBoundingClientRect().bottom;

      const modalConfirmed = await this.$refs.changeСellModal.open();

      modalConfirmed
        ? (this.changedStmtUuid = row.uuid)
        : this.clearСhangesStmt(false);
    },

    async openColSettings(target) {
      this.modalCoords.coordX = target.getBoundingClientRect().left;
      this.modalCoords.coordY = target.getBoundingClientRect().bottom;
      this.openedModalName = 'colSettings';

      await this.$refs.modal.open();

      this.openedModalName = null;
    },

    async openFilter(e) {
      this.openedModalName = e.name;

      this.modalCoords.coordX = e.coordX;
      this.modalCoords.coordY = e.coordY;

      await this.$refs.modal.open();

      this.openedModalName = null;
    },

    async openCreateStmtModal() {
      this.createStmtModalOpen = true;
      await this.$refs.createStmtModal.open();
    },

    async closeCreateStmtModal() {
      this.createStmtModalOpen = false;
      await this.$refs.createStmtModal.close();
    },

    showAllCol() {
      this.tableHeaders.forEach(item => (item.show = true));
      localStorage.removeItem('disabled-cols');
    },
    changeСolActivity(idx) {
      this.tableHeaders[idx].show = !this.tableHeaders[idx].show;

      let disabledCols = JSON.parse(localStorage.getItem('disabled-cols'));

      if (!disabledCols) disabledCols = [];

      let itemIdx = disabledCols.indexOf(idx);

      itemIdx < 0 ? disabledCols.push(idx) : disabledCols.splice(itemIdx, 1);

      localStorage.setItem('disabled-cols', JSON.stringify(disabledCols));
    },

    changeStmt(e) {
      this.tableData.forEach(item => {
        if (item.uuid === e.uuid) Object.assign(item, e);
      });
    },
    async confirmChanges(e) {
      this.inputType === 'datepicker'
        ? (this.changedStmtParams[e.key] = this.formattedDate(e.value))
        : (this.changedStmtParams[e.key] = this.toNumber(e.value));

      await this.$refs.changeСellModal.confirm();
    },
    filterBy(target, value) {
      const uuid = Object.keys(value)[0];
      const selected = Object.keys(this.filter[target]);

      // Если по агенту/банку уже идет фильтрация и в списке фильтра только он,
      // то игнорируем нажатие, иначе делаем фильтрацию только по нему
      if (selected.find(item => item === uuid) && selected.length === 1) {
        return;
      }
      this.$store.dispatch('CLEAR_FILTER', ['banks', 'agents']);
      this.$store.dispatch('SET_FILTER', {
        name: target,
        value,
      });
    },
    clearFilter() {
      this.sortBy.type = null;
      this.sortBy.name = null;

      this.$store.dispatch('CLEAR_FILTER');

      localStorage.removeItem('sortBy');
      this.getTableData();
    },

    cancelChanges() {
      this.clearСhangesStmt();
    },
    clearСhangesStmt(clearAll = true) {
      clearAll
        ? (this.changedStmtParams = {})
        : delete this.changedStmtParams[this.openForChangeStmtKey];

      if (clearAll && Object.keys(this.changedStmtParams).length === 0)
        this.changedStmtUuid = null;

      this.openForChangeStmtKey = null;
      this.inputType = null;
    },
    tableScroll(e) {
      this.$refs.tableHeader.scrollLeft = e.target.scrollLeft;
    },
    getBankNameByUuid(uuid) {
      const name = this.banksDictionary[uuid];

      return name ? name : uuid;
    },
    getLawNameByUuid(uuid) {
      const name = this.lawsDictionary[uuid];

      return name ? name : uuid;
    },
    getProductNameByUuid(uuid) {
      const name = this.productsDictionary[uuid].name;

      return name ? name : uuid;
    },
    getProductTypeByUuid(uuid, typeUuid) {
      const name = this.productsDictionary[uuid]?.types[typeUuid];

      return name ? name : uuid;
    },
    getColumnByName(name) {
      return this.tableHeaders.find(col => col.name === name);
    },

    pageChanged(e) {
      this.page = e;
      this.getTableData();
    },
    itemsOnPageChanged(e) {
      this.itemsOnPage = e;
      this.getTableData();
    },
    toNumber,
    formattedDate,
    toCurrency,
  },
};
</script>

<style lang="scss">
.stmts-page {
  position: fixed;
  top: 60px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  font-size: 0.8rem;

  .n-table {
    height: calc(100% - 42px - 52px);

    & .cell {
      padding-left: 15px;
      padding-right: 15px;
    }

    td[data-column-name='client'] .cell,
    td[data-column-name='auction_number'] .cell,
    td[data-column-name='agent_name'] .cell,
    td[data-column-name='manager_name'] .cell,
    td[data-column-name='bank_uuid'] .cell {
      display: inline-flex;
      flex-direction: column;
      overflow: hidden;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &__row--changed {
      z-index: 20;
      position: relative;
      box-shadow: 0px 5px 5px -5px rgba(34, 60, 80, 0.6),
        0px -5px 5px -5px rgba(34, 60, 80, 0.6);

      & .n-table__cell,
      &:hover .n-table__cell {
        background-color: #ebf5e6;
      }
    }
  }

  .n-table--blocked {
    .n-table__body-wrapper {
      overflow-y: hidden;
    }
  }
}

.n-table__row-actions {
  display: flex;
  z-index: 100;
  position: fixed;
  right: $scrollbar-width;
  background-color: #ebf5e6;
  padding: 8px;
  box-shadow: $shadow-base;

  button {
    margin: 0 5px;
  }
}

.change-form form {
  display: flex;
  flex-direction: column;
  width: 50%;

  input {
    margin-bottom: 5px;
  }
}

.btn-settings {
  position: relative;
}

.colSettings {
  color: $color-text-primary;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__item {
    padding: 5px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  input {
    margin-right: 5px;
  }
}
</style>
