<template>
  <div></div>
</template>

<script>
export default {
  name: 'login',
  data() {
    return {};
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
  },
  async created() {
    if (this.$route.query.code) {
      await this.$store.dispatch('LOGIN', {
        code: this.$route.query.code,
      });

      this.$route.query = {};
    }

    if (this.isLoggedIn) this.$router.push({ name: 'StmtsPage' });
  },
  mounted() {},
  methods: {},
  watch: {
    isLoggedIn() {
      if (this.isLoggedIn) this.$router.push({ name: 'StmtsPage' });
    },
  },
};
</script>
