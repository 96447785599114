import { fetchData } from '../../services/fetch';
import DsService from '../../services/ds-service';

const base = process.env.VUE_APP_API_URL;
const digSign = new DsService();

/* AV */

/**
 * Получить данные таблицы AV
 * @param {String} period - выбранный период
 */
const getTableDataAv = async period => {
  const periodDt = period ? `?period_dt=${period}` : '';

  try {
    const { ok, response } = await fetchData(`${base}/av${periodDt}`);

    if (!ok) throw 'Ошибка при получении данных таблицы AV';

    return {
      ok: true,
      data: response.data,
    };
  } catch (error) {
    console.error(error);
    return {
      ok: false,
      error,
    };
  }
};

/**
 * Пересчитать отчёт
 * @param {Object} params
 * @param {String} params.uuid - uuid агента
 * @param {String} params.period - период в формате "2022-03-01"
 */
const recalculateAv = async ({ uuid, period }) => {
  try {
    const { ok, response } = await fetchData({
      url: `${base}/av/calculate?agent_uuid=${uuid}&period_dt=${period}
      `,
      method: 'PATCH',
    });

    if (!ok) throw 'Ошибка при пересчёте отчёта';

    return {
      ok: true,
      data: response.data,
    };
  } catch (error) {
    console.error(error);
    return {
      ok: false,
      error,
    };
  }
};

/**
 * Отозвать отчёт
 * @param {String} params
 * @param {String} params.uuid - uuid агента
 * @param {Number} params.year - год
 * @param {Number} params.year - месяц
 */
const revokeReport = async ({ uuid, year, month }) => {
  try {
    const { ok, response } = await fetchData({
      url: `${base}/av/${uuid}/reports/${year}/${month}/back`,
      method: 'POST',
    });

    if (!ok) throw 'Ошибка при отзыве отчёта';

    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

/**
 * Отправить отчёт
 * @param {String} params
 * @param {String} params.uuid - uuid агента
 * @param {Number} params.year - год
 * @param {Number} params.year - месяц
 */
const sendReport = async ({ uuid, year, month }) => {
  try {
    const { ok, response } = await fetchData({
      url: `${base}/av/${uuid}/reports/${year}/${month}/send_to_agent`,
      method: 'POST',
    });

    if (!ok) throw 'Ошибка при отправке отчёта';

    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

/**
 * Загрузить отчет
 * @param {String} year - год
 * @param {String} month - месяц
 */
const uploadReport = async ({ uuid, year, month, hash, type }) => {
  try {
    const { ok, response } = await fetchData({
      url: `${base}/av/${uuid}/reports/${year}/${month}/send_to_agent`,
      method: 'POST',
      data: {
        type,
        hash,
      },
    });

    if (!ok) throw 'Ошибка при загрузке отчёта';

    return { ok: true, report: response.data };
  } catch (error) {
    console.error(error);
    return { ok: false, error: error };
  }
};

/**
 * Скачать отчёт - генерирует файл для скачивания
 * @param {String} params
 * @param {String} params.uuid - uuid агента
 * @param {Number} params.year - год
 * @param {Number} params.year - месяц
 */
const downloadReport = async ({ uuid, year, month }) => {
  try {
    const { ok, response } = await fetchData({
      url: `${base}/av/${uuid}/reports/${year}/${month}/download?ts=${new Date().getTime()}`,
      parse: false,
    });

    if (!ok) throw 'Ошибка при скачивании отчёта';

    const blob = await response.blob();
    const file = new File([blob], `${year}_${month}_report.docx`);
    const link = document.createElement('a');

    link.download = file.name;
    link.href = URL.createObjectURL(file);
    link.click();
    URL.revokeObjectURL(link.href);

    return { ok: true };
  } catch (error) {
    console.error('Ошибка при скачивании отчёта', error);
    return { ok: false, error: error };
  }
};

/**
 * Скачать подписанный отчёт - генерирует файл для скачивания
 * @param {String} params
 * @param {String} params.uuid - uuid агента
 * @param {Number} params.year - год
 * @param {Number} params.year - месяц
 */
const downloadSignReport = async ({ uuid, year, month }) => {
  try {
    const { ok, response } = await fetchData({
      url: `${base}/av/${uuid}/reports/${year}/${month}/sign?ts=${new Date().getTime()}`,
      parse: false,
    });

    if (!ok) throw 'Ошибка при скачивании отчёта';

    const blob = await response.blob();
    const file = new File([blob], `${year}_${month}_report.docx.sig`);
    const link = document.createElement('a');

    link.download = file.name;
    link.href = URL.createObjectURL(file);
    link.click();
    URL.revokeObjectURL(link.href);

    return { ok: true };
  } catch (error) {
    console.error('Ошибка при скачивании отчёта', error);
    return { ok: false, error: error };
  }
};

/**
 * Скачать отчет в формате base64
 * @param {Object} params
 * @param {String} params.year - год
 * @param {String} params.month - месяц
 */
const getReportInBase64 = async ({ uuid, year, month }) => {
  try {
    const { ok, response } = await fetchData({
      url: `${base}/av/${uuid}/reports/${year}/${month}/download?ts=${new Date().getTime()}`,
      parse: false,
    });

    if (!ok) throw 'Ошибка при скачивании отчёта';

    const blob = await response.blob();
    const reader = new FileReader();
    reader.readAsDataURL(blob);

    const result = await new Promise(resolve => {
      reader.onload = e => {
        const header = ';base64,';
        const fileData = e.target.result;
        const base64Data = fileData.substr(
          fileData.indexOf(header) + header.length,
        );
        resolve(base64Data);
      };
    });
    return { ok: true, report: result };
  } catch (error) {
    console.error(error);
    return { ok: false, error: error };
  }
};

/**
 * Получить список сертификатов
 */
const getCertificatesList = async () => {
  try {
    await digSign.init();

    if (!digSign.pluginReady) throw 'Ошибка при получении списка сертификатов';

    const { ok, certificates } = await digSign.getCertificates();

    if (!ok) throw 'Ошибка при получении списка сертификатов';

    return { ok: true, certificates };
  } catch (error) {
    console.error(error);
    return { ok: false, error: error };
  }
};

/**
 * Подписываем данные
 * @param {Object} params
 * @param {Object} params.cert - сертификат
 * @param {String} params.dataToSign - данные на подписание в base64
 */
const signData = async ({ cert, dataToSign }) => {
  try {
    const signedData = await digSign.sign(cert, dataToSign);

    if (!signedData) throw 'Ошибка при подписании отчета';

    return { ok: true, data: signedData };
  } catch (error) {
    console.error(error);
    return { ok: false, error: error };
  }
};

export {
  getTableDataAv,
  recalculateAv,
  revokeReport,
  sendReport,
  uploadReport,
  downloadReport,
  downloadSignReport,
  getReportInBase64,
  getCertificatesList,
  signData,
};
