<template>
  <section class="bn-chat">
    <div class="bn-chat__header">
      <div class="bn-chat__header-title">{{ init.name }}</div>
      <button
        @click="$emit('close')"
        type="button"
        class="bn-chat__header-close btn"
      >
        <bn-icon icon="close" />
      </button>
    </div>
    <div class="bn-chat__body">
      <div class="messages">
        <div class="messages__list" ref="messagesList">
          <bn-chat-message
            v-for="(msg, idx) in messages"
            :key="idx"
            :msg="msg"
            @download-atachment="downloadMsgAtachment"
            @open-context-menu="messageContextMenuOpen"
            @close-context-menu="contextMenu.open = false"
          />
          <context-menu
            :config="contextMenu"
            @copy-msg="copyMessageHandler"
            @edit-msg="editMessageHandler"
            @delete-msg="deleteMessageHandler"
          />
        </div>
      </div>
      <div v-if="error" class="bn-chat__alerts bn-alerts">
        <bn-icon class="bn-alerts__icon" icon="alert-circle-outline" />
        <div class="bn-alerts__item">{{ error }}</div>
      </div>
    </div>
    <div class="bn-chat__footer">
      <div class="attached-files">
        <div class="attached-files__list">
          <div
            class="attached-files__item"
            v-for="(file, idx) in files"
            :key="idx"
          >
            <span class="attached-files__name" :title="file.name">
              {{ file.name }}
            </span>
            <span class="attached-files__size">
              {{ (file.size / 1000).toFixed(1) }} KB
            </span>
            <button
              @click="deleteAttachedFile(idx)"
              class="btn attached-files__btn-delete"
            >
              <bn-icon icon="delete" />
            </button>
          </div>
        </div>
      </div>
      <form class="chat-controls" @submit.prevent="sendMessage">
        <div class="attach-files">
          <button
            @click="attachFiles"
            type="button"
            class="chat-controls__btn attach-files__btn"
          >
            <bn-icon icon="paperclip" />
            <input
              ref="inputFile"
              @change="attachedFilesHandler"
              accept="*"
              type="file"
              multiple=""
              style="display: none"
            />
          </button>
        </div>
        <div class="scrollable-container">
          <div class="text-area">
            <div class="text-area__wrap">
              <div
                v-if="!text"
                class="text-area__placeholder"
                aria-hidden="true"
              >
                Введите сообщение
              </div>
              <div
                ref="textArea"
                v-text="text"
                @keydown.enter.exact.prevent="sendMessage"
                @keydown.enter.shift.exact.prevent
                @input="onInput"
                class="text-area__message"
                aria-label="Введите сообщение"
                contenteditable="true"
              ></div>
            </div>
          </div>
        </div>
        <div class="send-messages">
          <button
            v-if="!editableMessage"
            @click="sendMessage"
            type="button"
            class="chat-controls__btn"
          >
            <bn-icon icon="send" />
          </button>
          <div v-else class="chat-controls__edit">
            <button
              @click="clearEditing"
              type="button"
              class="chat-controls__btn chat-controls__cancel"
            >
              <bn-icon icon="close" />
            </button>
            <button
              @click="saveEditedMessage"
              type="button"
              class="chat-controls__btn chat-controls__save"
            >
              <bn-icon icon="check" />
            </button>
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import BnChatMessage from './BnChatMessage';
import contextMenu from './BnChatContextMenu';
import { formattedDate, formattedTime, toBase64 } from '../../helpers/index';
import {
  getMessages,
  sendMessage,
  editMessage,
  deleteMessage,
  downloadAtachment,
} from './api-chat';

export default {
  components: { BnChatMessage, contextMenu },
  props: {
    init: { type: Object, required: false },
    period: { type: Object, required: false },
  },
  data() {
    return {
      messages: [],
      files: [],
      text: null,
      editableMessage: null,
      canSendMessages: false,
      submitBtnDisabled: false,
      contextMenu: {
        open: false,
        msgUuid: null,
        can: {
          delete: null,
          update: null,
        },
        coordX: null,
        coordY: null,
      },

      error: null,
    };
  },
  computed: {},
  mounted() {},
  watch: {
    async init({ uuid }) {
      this.messages = [];
      this.error = null;
      this.clearEditing();

      if (!uuid) return;

      const { ok, messages, error } = await getMessages({
        id: uuid,
        period: this.period,
      });
      if (!ok) {
        this.errorHadler(error);
        return;
      }

      this.messages = messages;
    },
  },
  methods: {
    attachFiles() {
      this.$refs.inputFile.click();
    },
    messageContextMenuOpen({ msgUuid, coordX, coordY, can }) {
      this.contextMenu = {
        open: true,
        msgUuid,
        coordX,
        coordY,
        can,
      };
    },
    deleteAttachedFile(idx) {
      this.files.splice(idx, 1);
    },
    onInput(e) {
      this.text = e.target.innerText;
    },
    clearEditing() {
      this.text = null;
      this.editableMessage = null;
      this.files = [];
    },
    async downloadMsgAtachment({ msgUuid, attachment }) {
      const { ok, error } = await downloadAtachment({
        id: this.init.uuid,
        period: this.period,
        messageId: msgUuid,
        attachmentId: attachment.uuid,
        attachmentName: attachment.name,
      });

      if (!ok) this.errorHadler(error);
    },
    async attachedFilesHandler(e) {
      Object.values(e.target.files).map(async file => {
        try {
          const result = await toBase64(file);

          this.files.push({
            name: file.name,
            hash: result.replace(/^data:(.*,)?/, ''),
            size: file.size,
            mime: file.type,
          });
        } catch (error) {
          this.errorHadler(error);
          console.error(error);
        }
      });

      this.$refs.inputFile.value = '';
    },
    async sendMessage() {
      if (!this.text && this.files.length === 0) return;

      const { ok, message, error } = await sendMessage({
        id: this.init.uuid,
        period: this.period,
        data: {
          text: this.text,
          attachments: this.files,
        },
      });

      if (!ok) {
        this.errorHadler(error);
        return;
      }

      this.messages.push(message);
      this.clearEditing();

      this.$nextTick(() => {
        this.$refs.messagesList.scrollTop +=
          this.$refs.messagesList.scrollHeight -
          this.$refs.messagesList.clientHeight;
      });
    },
    async saveEditedMessage() {
      const idx = this.getMessageIdxByUuid(this.editableMessage.uuid);

      this.editableMessage.text = this.text;
      this.editableMessage.attachments = this.files.map(item => {
        const result = item;
        result.hash = result.hash || '';
        return result;
      });

      if (
        !!this.editableMessage.text ||
        !!this.editableMessage.attachments.length > 0
      ) {
        const { ok, message, error } = await editMessage({
          id: this.init.uuid,
          period: this.period,
          message: this.editableMessage,
        });

        if (ok) {
          this.messages[idx] = message;
          this.clearEditing();
        } else {
          this.errorHadler(error);
        }

        return;
      }

      this.deleteMessageHandler(this.editableMessage.uuid);
    },
    copyMessageHandler(uuid) {
      const text = this.messages.find(message => message.uuid === uuid).text;

      navigator.clipboard.writeText(text).catch(err => {
        console.log('Ошибка при копировании в буфер обмена', err);
      });
    },
    editMessageHandler(uuid) {
      this.editableMessage = {
        ...this.messages.find(message => message.uuid === uuid),
      };

      this.text = this.editableMessage.text;
      this.files = [...this.editableMessage.attachments];
    },
    async deleteMessageHandler(msgUuid) {
      const idx = this.getMessageIdxByUuid(msgUuid);

      const { ok, error } = await deleteMessage({
        id: this.init.uuid,
        period: this.period,
        uuid: msgUuid,
      });

      ok ? this.messages.splice(idx, 1) : this.errorHadler(error);
    },
    getMessageIdxByUuid(uuid) {
      return this.messages.findIndex(message => message.uuid === uuid);
    },
    errorHadler(error) {
      this.error = error;
      setTimeout(() => {
        this.error = null;
      }, 2000);
    },
    formattedDate,
    formattedTime,
  },
};
</script>

<style lang="scss" scoped>
.bn-chat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 0.5em 1em;
    z-index: 20;
    width: 100%;
    height: 50px;
    background-color: #eee;
    border-bottom: 1px solid #dfdfdf;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12);

    &-title {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 10px;
    }
    &-close {
      svg {
        width: 24px;
        height: 24px;
        fill: lighten($color-primary, 30%);
      }
    }
  }
  &__body {
    position: relative;
    z-index: 10;
    background-color: #f8f2e9;
    width: 100%;
    flex: 1;
  }
  &__footer {
    position: relative;
    z-index: 20;
    width: 100%;
    padding: 1.2em 0.2em;
    background-color: #eee;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.12);
  }
}
.messages {
  position: relative;
  height: 100%;
  width: 100%;

  &__list {
    position: absolute;
    top: 0;
    z-index: 100;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    transition: background 0.3s ease-out 0.1s;
  }
}
.scrollable-container {
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #eee;
  flex: 1;
  max-height: 50vh;
  scrollbar-width: none;
}
.chat-controls {
  display: flex;
  align-items: center;
  width: 100%;

  &__btn {
    height: 30px;
    width: 30px;
    padding: 5px;
    fill: #54656f;

    svg {
      max-width: 100%;
      max-height: 100%;
    }
  }
  &__edit {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: -5px;
  }
  &__cancel {
    height: 24px;
    width: 24px;
    margin-bottom: 15px;
  }
  &__save {
    fill: $color-success;
    height: 36px;
    width: 36px;
  }
}
.attached-files {
  &__list {
    padding: 0.5em 1em;
  }
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 10px;
    padding-bottom: 14px;
  }
  &__name {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 10px;
  }
  &__size {
    position: absolute;
    bottom: 2px;
    left: 0;
    font-size: 0.6rem;
    color: #959595;
  }
  &__btn-delete {
    width: 16px;
    height: 16px;

    svg {
      max-width: 100%;
      max-height: 100%;
      fill: $color-danger;
    }
  }
}
.text-area {
  &__wrap {
    position: relative;
    padding: 0.5em 1em;
    border-radius: 8px;
    background-color: #fff;
  }
  &__placeholder {
    position: absolute;
    top: 0;
    left: 0;
    right: 10px;
    padding: 0.5em 1em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    user-select: none;
    pointer-events: none;
    color: #8d8d8d;
  }
  &__message {
    p {
      margin: 0;
    }
  }
}
.bn-alerts {
  width: 100%;
  position: absolute;
  bottom: 10px;
  padding: 20px;

  &__item {
    background-color: #fefefe;
    box-shadow: rgba(11, 20, 26, 0.13) 0px 1px 0.5px 0px;
    padding: 1em 1em 1em 2.4em;
    border-radius: 8px;
  }
  &__icon {
    width: 20px;
    height: 20px;
    fill: $color-danger;
    position: absolute;
    left: 25px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
