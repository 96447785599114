<template>
  <bn-av-table />
</template>
<script>
import BnAvTable from '../components/BnAvTable/BnAvTable.vue';

export default {
  components: { BnAvTable },
};
</script>
