<template>
  <header class="header">
    <div class="header__logo">
      <a href="/"
        ><img class="header__logo" alt="logo" src="@/assets/img/logo.svg" />
      </a>
    </div>
    <div id="nav" v-if="isLoggedIn">
      <router-link :to="{ name: 'StmtsPage' }">Сводная таблица</router-link>
      | <router-link :to="{ name: 'KvPage' }">Расчет КВ</router-link> |
      <router-link :to="{ name: 'AvPage' }">Расчет АВ</router-link>
    </div>
    <div class="header__cont">
      <span class="header__phone"> +7&nbsp;499&nbsp;704&nbsp;28&nbsp;27</span>

      <div class="header__auth" v-if="isLoggedIn">
        <bn-button-group>
          <bn-button color="success">{{ userName }}</bn-button>
          <template #dropdown>
            <bn-button @click="$emit('logout')" color="danger">Выход</bn-button>
          </template></bn-button-group
        >
      </div>
    </div>
  </header>
</template>

<script>
export default {
  props: {
    userName: { type: String, required: false },
  },
  data() {
    return {};
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
.header {
  background: #4e4f61;
  color: #fff;
  border-bottom: 1px #474856 solid;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;

  &__logo {
    padding: 0;

    img {
      width: 190px;
    }
  }
  a {
    color: #fff;
  }
  .router-link-active {
    color: $color-success;
  }
  &__cont {
    display: flex;
  }
  &__phone {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    margin-right: 20px;
  }
}
</style>
