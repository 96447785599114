<template>
  <div
    class="message message-in"
    :class="msg?.direction ? `message-${msg.direction_type}` : ''"
  >
    <div
      @contextmenu.prevent="openContextMenu"
      v-click-outside="closeContextMenu"
      class="message__bg"
    >
      <div v-if="msg.text" class="message__main">
        <span class="message__text">{{ msg.text }}</span>
      </div>
      <div v-if="msg?.attachments.length > 0" class="attachments">
        <div
          class="attachments__item"
          v-for="(attachment, idx) in msg.attachments"
          :key="idx"
        >
          <span class="attachments__name" :title="attachment.name">{{
            attachment.name
          }}</span>
          <span class="attachments__size"
            >{{ (attachment.size / 1000).toFixed(1) }} KB</span
          >
          <button
            class="attachments__btn"
            @click="
              $emit('download-atachment', {
                msgUuid: msg.uuid,
                attachment: attachment,
              })
            "
          >
            <bn-icon icon="download" />
          </button>
        </div>
      </div>
      <span class="message__date">
        {{ formattedDate(msg.created_at, '.') }}
        {{ formattedTime(msg.created_at) }}
      </span>
    </div>
  </div>
</template>

<script>
import { formattedDate, formattedTime } from '../../helpers/index';

export default {
  ContextMenuWidth: 130,
  ContextMenuHeight: 120,
  props: {
    msg: { type: Object, required: false },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  watch: {},
  methods: {
    openContextMenu(e) {
      const chat = e.target.closest('.bn-chat-wrapper');
      let coordX = e.clientX - chat.getBoundingClientRect().left;
      let coordY = e.clientY;

      if (window.innerWidth - e.clientX < this.$options.ContextMenuWidth)
        coordX = coordX - this.$options.ContextMenuWidth;
      if (window.innerHeight - e.clientY < this.$options.ContextMenuHeight)
        coordY = coordY - 80;

      this.$emit('open-context-menu', {
        msgUuid: this.msg.uuid,
        coordX: coordX,
        coordY: coordY,
        can: this.msg.can,
      });
    },
    closeContextMenu() {
      this.$emit('close-context-menu');
    },
    formattedDate,
    formattedTime,
  },
};
</script>

<style lang="scss" scoped>
.message {
  display: flex;
  flex-direction: column;
  padding: 0.5em 1em;

  &-in {
    align-items: flex-start;
  }
  &-out {
    align-items: flex-end;
  }
  &__bg {
    max-width: 75%;
    padding: 6px 8px;
    border-radius: 5px;
    box-shadow: rgba(11, 20, 26, 0.13) 0px 1px 0.5px 0px;
  }
  &__text {
    display: block;
    margin-bottom: 4px;
    padding-right: 30px;
    overflow-wrap: break-word;
    white-space: pre-wrap;
  }
  &__date {
    display: block;
    text-align: right;
    font-size: 0.6rem;
    color: $color-text-primary-lighter;
  }
  &-in &__bg,
  &-in &__attachments-item {
    background-color: #fff;
  }
  &-out &__bg,
  &-out &__attachments-item {
    background-color: #d8ffe5;
  }
}
.attachments {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 4px 0;

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    padding-bottom: 12px;
  }

  &__name {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 10px;
    font-weight: 600;
  }
  &__size {
    position: absolute;
    bottom: 2px;
    left: 0;
    font-size: 0.6rem;
    color: #959595;
  }

  &__btn {
    width: 20px;
    height: 20px;

    svg {
      max-width: 100%;
      max-height: 100%;
      fill: $color-success;
    }
  }
}
</style>
