const monthLocalized = [
  'январь',
  'февраль',
  'март',
  'апрель',
  'май',
  'июнь',
  'июль',
  'август',
  'сентябрь',
  'октябрь',
  'ноябрь',
  'декабрь',
];

const toCurrency = value => {
  return new Intl.NumberFormat('ru-RU', {
    minimumFractionDigits: 2,
  }).format(value);
};

const toNumber = value => {
  const clearValue = Number(String(value).replace(/\s/g, '').replace(',', '.'));
  return clearValue;
};
/**
 * Принимает дату в формате валидном для new Date и возвращает дату в формате '30.06.2022'
 * @param {String | Object} date - дата строка или объект new Date
 * @param {String} separator - разделитель
 */
const formattedDate = (date, separator = '-') => {
  if (!date) return null;

  let year = new Date(date).getFullYear(),
    mount = new Date(date).getMonth() + 1,
    day = new Date(date).getDate();

  mount = mount < 10 ? '0' + mount : mount;
  day = day < 10 ? '0' + day : day;

  return year + separator + mount + separator + day;
};
/**
 * Принимает дату в формате валидном для new Date и возвращает время в формате '16:35'
 * @param {String | Object} date - дата строка или объект new Date
 */
const formattedTime = date => {
  let hours = new Date(date).getHours(),
    minutes = new Date(date).getMinutes();

  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;

  return `${hours}:${minutes}`;
};

const normalizedDateFromObj = date => {
  if (!date.year || !date.month) return null;
  let mount = date.month < 10 ? '0' + date.month : date.month;

  return `${date.year}-${mount}-01`;
};

const localizedDateFromObj = date => {
  if (!date.year || !date.month) return null;
  return `${monthLocalized[date.month - 1]}, ${date.year}`;
};
/**
 * Преобразует файл в base64 строку
 * @param {File | Blob} file - Blob или File
 */
const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

const sleep = duration =>
  new Promise(resolve => {
    setTimeout(resolve, duration);
  });

export {
  toCurrency,
  toNumber,
  formattedDate,
  formattedTime,
  normalizedDateFromObj,
  localizedDateFromObj,
  toBase64,
  sleep,
};
