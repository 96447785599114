<template>
  <div class="">
    <div class="list">
      <div class="list__item">
        <label>
          <input
            class="el-radio__original"
            type="radio"
            name="paid_dt"
            value="current"
            :checked="picked === 'current'"
            v-model="picked"
          />
          <span>За текущий период</span>
        </label>
      </div>
      <div class="list__item">
        <label>
          <input
            class="el-radio__original"
            type="radio"
            name="paid_dt"
            value="choice"
            v-model="picked"
            :checked="datepickerShow"
          />
          <span>Выбрать период</span>
        </label>

        <div v-show="datepickerShow">
          <el-config-provider :locale="locale">
            <datepicker
              v-model="inputDate"
              :disabled-date="disabledDate"
              type="month"
              placeholder="Выберите месяц"
            />
          </el-config-provider>
        </div>
      </div>
    </div>
    <div class="actions-block">
      <bn-button @click="applyFilter" color="success">Показать</bn-button>
    </div>
  </div>
</template>

<script>
import ru from 'element-plus/lib/locale/lang/ru';
import { ElConfigProvider } from 'element-plus';
import { formattedDate } from '../../../helpers/index';

export default {
  components: {
    ElConfigProvider,
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      locale: ru,
      filter: '',
      inputDate: null,
      picked: null,
      datepickerShow: false,
    };
  },
  computed: {
    period() {
      return this.$store.state.filter.period;
    },
  },
  mounted() {
    if (this.period) {
      this.datepickerShow = true;
      this.picked = 'choice';
      this.inputDate = this.period;
    } else {
      this.picked = 'current';
    }
  },
  watch: {
    period(value) {
      this.datepickerShow = !!value;
      this.inputDate = value;
    },
    picked(value) {
      this.datepickerShow = value === 'choice';
    },
  },
  methods: {
    disabledDate(time) {
      return time.getTime() > Date.now();
    },
    applyFilter() {
      this.$store.dispatch('SET_FILTER', {
        name: 'period',
        value: this.picked === 'choice' ? formattedDate(this.inputDate) : null,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  margin-bottom: 10px;

  &__item {
    label {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 5px 0;

      input {
        margin-right: 10px;
      }
    }
  }
}
</style>
