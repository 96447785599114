<template>
  <div class="number-filter">
    <div class="flex justify-center align-center">
      <input v-model="filter" type="text" placeholder="Найти..." class="mr-1" />
      <bn-button
        @click="applyFilter"
        color="success"
        icon="check"
        class="mr-1"
        :disabled="filter === '' || loading"
      />
      <bn-button color="danger" icon="close" @click="$emit('close')" />
    </div>
    <div v-if="number" class="number-filter__selected">
      <bn-button :disabled="loading" icon="close" color="success" @click="clear"
        >{{ number }}
      </bn-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['close'],

  data() {
    return {
      filter: '',
    };
  },
  computed: {
    number() {
      return this.$store.state.filter.number;
    },
  },
  methods: {
    applyFilter() {
      this.$store.dispatch('SET_FILTER', {
        name: 'number',
        value: this.filter,
      });
    },
    clear() {
      this.$store.dispatch('SET_FILTER', {
        name: 'number',
        value: null,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.number-filter {
  &__selected {
    padding-top: 8px;
  }
}
</style>
