<template>
  <div class="flex justify-end items-center">
    <bn-button
      v-if="row.can.send_to_agent"
      @click="$emit('sign-report')"
      color="success"
      icon="file-sign"
      title="Подписать и отправить"
      class="mr-2"
    >
      Подписать
    </bn-button>

    <bn-button
      v-if="row.can.back"
      @click="$emit('revoke')"
      :disabled="completed"
      color="danger"
      icon="text-box-remove-outline"
      title="Отозвать отчёт"
      class="mr-2"
    >
      Отозвать
    </bn-button>

    <bn-dropdown-menu
      v-if="agentSigned || accountantSigned"
      class="bn-dropdown--download"
      :is-open="isOpen"
      @close="isOpen = false"
    >
      <bn-button
        @click="openDownloadsMenu"
        square
        icon="download"
        title="Скачать отчёт"
      />

      <template #dropdown>
        <bn-button
          @click="$emit('download')"
          icon="file-document-outline"
          title="Скачать отчёт"
          >Скачать исходный отчёт
        </bn-button>
        <bn-button
          @click="$emit('download-sign')"
          icon="file-sign"
          title="Скачать отчёт"
          >Скачать подписанный отчёт
        </bn-button>
      </template>
    </bn-dropdown-menu>

    <bn-button
      v-else
      @click="$emit('download')"
      square
      icon="download"
      title="Скачать отчёт"
    />
  </div>
</template>

<script>
import { formattedDate, formattedTime } from '../../../helpers/index';
export default {
  props: {
    row: { type: Object, required: false, default: () => {} },
  },
  data() {
    return { isOpen: false };
  },
  computed: {
    completed() {
      return (
        this.row?.accountant_status?.status && this.row?.agent_status?.status
      );
    },
    agentSigned() {
      return this.row?.agent_status?.status;
    },
    accountantSigned() {
      return this.row?.accountant_status?.status;
    },
  },
  methods: {
    forcedRevoke() {
      if (!this.completed) return;

      this.$emit('forced-revoke');
    },
    openDownloadsMenu() {
      console.log('dd');
      this.isOpen = true;
    },
    formattedDate,
    formattedTime,
  },
};
</script>

<style lang="scss" scoped>
.sign-status {
  fill: #a7a7a7;

  &--success {
    fill: $color-success;
  }
}
.bn-dropdown--download {
  .bn-dropdown__menu {
    background-color: #fff;
    border-radius: $border-radius;
    box-shadow: $shadow-base;
    padding: 5px;

    button {
      width: 100%;
      margin-bottom: 4px;
      border: 0;
    }
    button:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
