<template>
  <button :class="classObject" class="bn-button" type="button">
    <bn-icon v-if="icon" :icon="icon" />
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    color: { type: String, required: false },
    round: { type: String, required: false },
    square: { type: String, required: false },
    icon: { type: String, required: false },
  },
  computed: {
    hasSlot() {
      return !!this.$slots.default;
    },
    classObject() {
      return {
        'bn-button--success': this.color === 'success',
        'bn-button--primary': this.color === 'primary',
        'bn-button--danger': this.color === 'danger',
        'bn-button--warning': this.color === 'warning',
        'bn-button--info': this.color === 'info',
        'is-circle': this.round === '',
        'is-square': this.square === '',
        'bn-button--with-icon': this.icon && this.hasSlot,
      };
    },
  },
};
</script>

<style lang="scss">
.bn-button {
  border: 0;
  border-radius: 4px;
  padding: $padding-button;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  height: 32px;
  white-space: nowrap;
  background-color: transparent;
  cursor: pointer;
  border: 1px solid #dcdfe6;
  color: $color-text-primary;
  fill: lighten($color-text-primary, 20%);

  &:hover,
  &:focus {
    background-color: #e0e0e0;
  }

  svg {
    min-height: 16px;
    height: 1em;
    min-width: 16px;
    width: 1em;
    line-height: 1em;
    pointer-events: none;
  }
  &--success {
    background-color: $color-success;
    border: 1px solid $color-success;
    color: #fff;
    fill: #fff;
    &:hover,
    &:focus {
      background-color: lighten($color-success, 10%);
    }
  }
  &--danger {
    background-color: $color-danger;
    border: 1px solid $color-danger;
    color: #fff;
    fill: #fff;
    &:hover,
    &:focus {
      background-color: lighten($color-danger, 10%);
    }
  }
  &--warning {
    background-color: $color-warning;
    border: 1px solid $color-warning;
    color: #fff;
    fill: #fff;
    &:hover,
    &:focus {
      background-color: lighten($color-warning, 10%);
    }
  }
  &--info {
    background-color: $color-info;
    border: 1px solid $color-info;
    color: #fff;
    fill: #fff;

    &:hover,
    &:focus {
      background-color: lighten($color-info, 10%);
    }
  }
  &--with-icon {
    justify-content: flex-start;
    padding: 8px 15px 8px 8px;

    svg {
      margin-right: 6px;
    }
  }
  &.is-circle {
    border-radius: 50%;
    padding: 8px;
  }
  &.is-square {
    width: 32px;
    padding: 5px;
  }
}
.bn-button[disabled] {
  opacity: 0.4;
}
</style>
