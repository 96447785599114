<template>
  <div class="v-preloader" :style="styleObject">
    <div class="v-preloader__animation" :style="styleObject"></div>
  </div>
</template>
<script>
export default {
  props: {
    size: { type: String, required: false },
  },
  data() {
    return {
      styleObject: {
        width: this.size + 'px',
        height: this.size + 'px',
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.v-preloader {
  position: absolute;
  top: 40%;
  left: 50%;
  z-index: 200;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  &__animation {
    display: inline-block;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: #ffa726;
    animation: spin 1.5s linear infinite;

    &:before {
      content: '';
      position: absolute;
      top: 3px;
      left: 3px;
      right: 3px;
      bottom: 3px;
      border-radius: 50%;
      border: 1px solid transparent;
      border-top-color: #34495e;
      animation: spin 3s linear infinite;
    }
  }
}
// Animations
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
