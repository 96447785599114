<template>
  <section class="create-stmt__page flex flex-col">
    <div class="form-group flex-1">
      <bn-input
        :hasError="formData.provision_sum.hasError"
        @input-changed="updateInput('provision_sum', toNumber($event))"
        label="Размер обеспечения"
        mask="money"
      />

      <bn-input
        :hasError="formData.base_tariff.hasError"
        @input-changed="updateInput('base_tariff', toNumber($event))"
        label="Базовый тариф"
        mask="money"
      />

      <bn-input
        v-if="managerSelected"
        :hasError="formData.manager_commission.hasError"
        @input-changed="updateInput('manager_commission', toNumber($event))"
        label="Превышение менеджера"
        mask="money"
      />

      <bn-input
        v-if="agentSelected"
        :hasError="formData.agent_commission.hasError"
        @input-changed="updateInput('agent_commission', toNumber($event))"
        label="Превышение агента"
        mask="money"
      />

      <bn-input label="Итоговая сумма">
        <div class="final-tariff">
          <b> {{ calculatedFinal }}</b>
        </div>
      </bn-input>
    </div>
  </section>
</template>

<script>
import { toNumber } from '../../helpers/index.js';

export default {
  props: {
    errorList: { type: Object, required: false, default: () => {} },
    formData: { type: Object, required: false, default: () => {} },
  },
  emits: ['update-form'],
  data() {
    return {};
  },
  computed: {
    agentSelected() {
      return !!this.formData.agent.value;
    },
    managerSelected() {
      return !!this.formData.manager.value;
    },
    calculatedFinal() {
      return (
        this.formData.base_tariff.value +
        this.formData.manager_commission.value +
        this.formData.agent_commission.value
      )
        .toFixed(2)
        .replace('.', ',');
    },
  },
  watch: {
    calculatedFinal(value) {
      this.updateInput('final_tariff', toNumber(value));
    },
  },

  methods: {
    updateInput(name, value) {
      this.$emit('update-form', { name, value });
    },
    toNumber,
  },
};
</script>

<style lang="scss" scoped>
.final-tariff {
  padding: 8px 15px;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
}
</style>
