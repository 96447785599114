<template>
  <div class="ds-block">
    <div class="ds-cert-list">
      <div
        class="ds-cert"
        :class="{ 'ds-cert--selected': thumbprint === cert.thumbprint }"
        v-for="cert in list"
        :key="cert.thumbprint"
        @click="thumbprint = cert.thumbprint"
      >
        <div class="ds-cert__param flex flex-col">
          <span class="ds-cert__param-name">Выдан:</span>
          <span class="ds-cert__param-value" :title="cert.subject.name">
            {{ cert.subject.name }}
          </span>
        </div>
        <div class="ds-cert__param flex flex-col">
          <span class="ds-cert__param-name">Действителен до:</span>
          <span class="ds-cert__param-value" :title="cert.validTo">
            {{ cert.validTo }}
          </span>
        </div>
      </div>
    </div>
    <div class="ds-block__actions flex justify-between items-center">
      <bn-button @click="$emit('cancel')" title="Отменить">
        Отменить
      </bn-button>
      <bn-button
        @click="sign"
        :disabled="!thumbprint"
        color="success"
        icon="file-sign"
        title="Подписать"
        >Подписать
      </bn-button>
    </div>
  </div>
</template>

<script>
import BnButton from '../ui/BnButton.vue';
export default {
  components: { BnButton },
  props: {
    list: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return { thumbprint: null };
  },
  methods: {
    sign() {
      this.$emit('selected', this.thumbprint);
    },
  },
};
</script>

<style lang="scss">
.ds-block {
  width: 380px;
  max-width: 100%;
  padding: 0 8px;

  &__actions {
    padding: 8px 0 0;

    button {
      padding: 20px;
      margin-right: 16px;
    }
    button:last-child {
      margin-right: 0;
    }
  }
}
.ds-cert-list {
  height: 210px;
  overflow: hidden;
  overflow-y: auto;

  ::-webkit-scrollbar-button {
    display: none;
    width: 0;
    height: 0;
    border: 0;
  }
}
.ds-cert {
  padding: 8px 16px;
  border: 1px solid #eee;
  border-radius: 6px;
  margin-bottom: 8px;
  cursor: pointer;

  &--selected {
    border: 1px solid $color-success;
    background-color: #67c23a10;
  }
  &__param {
    margin-bottom: 8px;
  }
  &__param-name {
    margin-right: 8px;
    width: 100%;
    display: inline-block;
    font-size: 0.9rem;
  }
  &__param-value {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 600;
  }
}
</style>
