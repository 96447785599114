<template>
  <div class="bn-select" v-click-outside="close">
    <template v-if="onlyOneOption">
      <input v-model="input" disabled type="text" class="bn-select__inner" />
    </template>

    <template v-else>
      <input
        v-model="input"
        @focus="showSelect"
        type="text"
        class="bn-select__inner"
      />
      <bn-icon
        v-if="input"
        @click="clearInput"
        :size="16"
        icon="close"
        class="bn-select__close"
      />

      <div v-if="show" class="bn-popover">
        <span class="bn-popover__arrow"></span>

        <div v-if="search" class="bn-select__filter">
          <input
            v-if="mask === 'inn'"
            v-mask="'############'"
            v-model="filter"
            ref="input"
            type="text"
          />
          <input v-else v-model="filter" ref="input" type="text" />
        </div>

        <div class="bn-select__list">
          <div
            v-for="(item, key) in filteredList"
            :key="key"
            @click="selectValue(key)"
            class="bn-select__list-item"
            :title="item"
          >
            <template v-if="typeof item === 'object'">
              <span v-for="(value, idx) in item" :key="idx">{{ value }}</span>
            </template>

            <template v-else>
              <span class="single">{{ item }}</span>
            </template>
          </div>

          <div class="bn-select__info">
            <span
              v-if="loadableList && filter === ''"
              class="bn-select__info-item"
              >Для поиска начните вводить название
            </span>

            <span
              v-if="
                filter !== '' && filter.length > 2 && !loading && nothingFound
              "
              class="bn-select__info-item"
              >По данному запросу ничего не найдено
            </span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    mask: { type: String, required: false, default: '' },
    query: { type: String, required: false, default: '' },
    value: { type: String, required: false, default: '' },
    search: { type: Boolean, required: false, default: false },
    loading: { type: Boolean, required: false, default: false },
    loadableList: { type: Boolean, required: false, default: false },
    list: { type: Object, required: false, default: () => {} },
  },
  emits: ['selected', 'update:value', 'update:query', 'clear', 'close'],
  data() {
    return {
      show: false,
      input: '',
      filter: '',
      onlyOneOption: null,
    };
  },
  mounted() {
    this.checkOptionCount(this.list);
  },
  computed: {
    nothingFound() {
      return Object.keys(this.filteredList).length === 0;
    },
    filteredList() {
      const filtered = {};

      if (!this.list) return filtered;
      if (this.loadableList) return this.list;

      Object.keys(this.list).filter(uuid => {
        const isValid = this.list[uuid]
          .toUpperCase()
          .includes(this.filter.toUpperCase());

        if (isValid) filtered[uuid] = this.list[uuid];
      });

      return filtered;
    },
  },
  watch: {
    list(newList) {
      this.checkOptionCount(newList);
    },
    filter(query) {
      this.$emit('update:query', query);
    },
  },
  methods: {
    checkOptionCount(list) {
      if (!list) return;
      this.onlyOneOption = Object.keys(list).length === 1;

      if (this.onlyOneOption) {
        const key = Object.keys(list)[0];
        const value = Object.values(list)[0];
        this.input = value;
        this.$emit('update:value', key);
        this.$emit('selected', { [key]: value });
      }
    },
    showSelect() {
      this.show = true;

      if (!this.search) return;

      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    selectValue(key) {
      const value = this.list[key];

      this.input =
        typeof value === 'object' ? this.list[key].name : this.list[key];
      this.$emit('update:value', key);
      this.$emit('selected', { [key]: this.list[key] });

      this.close();
    },
    clearInput() {
      this.input = '';
      this.$emit('clear');
    },
    close() {
      this.show = false;
      this.filter = '';
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.bn-select {
  position: relative;

  &__inner {
    width: 100%;
  }

  &__close {
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
    fill: #cfcfcf;
    cursor: pointer;

    &:hover {
      fill: #a0a0a0;
    }
  }

  &__filter {
    padding: 5px;

    input {
      width: 100%;
    }
  }

  &__list {
    max-height: 200px;
    overflow: hidden;
    overflow-y: auto;

    &-item {
      padding: 2px 32px 2px 20px;
      position: relative;
      box-sizing: border-box;
      cursor: pointer;

      &:hover {
        background-color: #f5f7fa;
      }

      span {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        height: 18px;
        line-height: 18px;
      }

      .single {
        height: 30px;
        line-height: 30px;
      }

      span:nth-child(2) {
        color: $color-text-primary-lighter;
      }
    }
  }

  &__info {
    padding: 0 5px;

    &-item {
      color: $color-text-primary-lighter;
      font-size: 0.7rem;
    }
  }
}

.bn-popover {
  position: absolute;
  top: 0;
  z-index: 1000;
  width: 100%;
  padding: 5px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.12);

  /*  &__arrow {
    position: absolute;
    top: -5px;
    left: 50%;
    width: 10px;
    height: 10px;
    z-index: -1;

    &:before {
      position: absolute;
      width: 10px;
      height: 10px;

      z-index: -1;
      content: '';
      transform: rotate(45deg);
      background: #fff;
      box-sizing: border-box;
    }
  } */
}
</style>
