<template>
  <bn-header :user-name="userName" @logout="logoutHandler" />
  <router-view v-show="isLoggedIn" />

  <bn-modal
    class="bn-overlay--light"
    ref="authModal"
    :click-outside="false"
    :show-header="false"
    :show-footer="false"
  >
    <bn-auth-form @logout="logoutHandler" @to-auth-service="toAuthService" />
  </bn-modal>
  <alerts-box />
</template>

<script>
import BnHeader from './components/BnHeader.vue';
import BnAuthForm from './components/BnAuthForm.vue';
import AlertsBox from './components/BnAlertsBox/BnAlertsBox.vue';

import {
  logout,
  getUserName,
  redirectToAuthService,
  getRefreshToken,
} from './services/authentication-service';
export default {
  name: 'App',
  components: {
    BnHeader,
    BnAuthForm,
    AlertsBox,
  },

  data() {
    return { userName: null, timerIsRunning: false };
  },
  created() {},
  async mounted() {
    this.$store.dispatch('RESTORE_FILTER');
    this.accessVerification();
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
    connectionStatus() {
      return this.$store.state.connectionStatus;
    },
  },
  methods: {
    async accessVerification() {
      // Если нет refresh токена, вызываем окно авторизации
      if (!getRefreshToken()) {
        this.$store.commit('SET_CONNECTION_STATUS', 'invalid refresh token');
        return;
      }

      if (!this.isLoggedIn && getRefreshToken()) {
        await this.$store.dispatch('REFRESH_TOKEN');
      }
    },
    async openAuthModal() {
      await this.$refs.authModal.open();
    },
    async closeAuthModal() {
      const isOpen = this.$refs.authModal.$data.dialogVisible;

      if (!isOpen) return;

      await this.$refs.authModal.close();
    },

    logoutHandler() {
      logout();
    },
    toAuthService() {
      redirectToAuthService();
    },
  },
  watch: {
    isLoggedIn(isLogged) {
      if (!isLogged) return;

      this.$store.dispatch('GET_DICTIONARIES');
    },
    connectionStatus() {
      if (this.connectionStatus === 'network error') {
        this.$swal({
          icon: 'error',
          title: 'Ошибка при получении данных, сервер не отвечает',
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 3000,
        });
      }
      this.connectionStatus === 'invalid refresh token'
        ? this.openAuthModal()
        : this.closeAuthModal();

      this.userName = getUserName();
    },
  },
};
</script>

<style lang="scss"></style>
