<template>
  <div class="bn-dropdown" v-click-outside="close">
    <div class="bn-dropdown__toggle">
      <slot></slot>
    </div>
    <div class="bn-dropdown__menu" v-if="isOpen">
      <slot name="dropdown"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isOpen: { type: Boolean, required: false, default: false },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss">
.bn-dropdown {
  position: relative;

  &__caret-button {
    width: 30px;
    border-left: none;

    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 1px;
      top: 5px;
      bottom: 5px;
      left: 0;
      background: rgba(255, 255, 255, 0.5);
    }
  }
  &__menu {
    position: absolute;
    top: 32px;
    right: 0;
    z-index: 100;

    border-radius: $border-radius;
    border: 1px solid #e5e7eb;
    background-color: #fff;
    box-shadow: $shadow-base;
    padding: 5px 2px;

    & .bn-button {
      margin: 0;
    }
  }
  &--open &__menu {
    display: flex;
    flex-direction: column;
  }
}
</style>
