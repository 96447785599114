import { createRouter, createWebHistory } from 'vue-router';
import Login from '../views/Login.vue';

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
  },
  {
    path: '/stmts',
    name: 'StmtsPage',
    meta: { title: 'Сводная таблица' },
    component: () => import('../views/StmtsPage.vue'),
  },
  {
    path: '/kv',
    name: 'KvPage',
    meta: { title: 'Расчет КВ' },
    component: () => import('../views/KvPage.vue'),
  },
  {
    path: '/av',
    name: 'AvPage',
    meta: { title: 'Расчет АВ' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/AvPage.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach(to => {
  document.title = to.meta.title || process.env.VUE_APP_TITLE;
});

export default router;
