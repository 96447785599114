<template>
  <bn-stmts-table />
</template>
<script>
import BnStmtsTable from '../components/BnStmtsTable/BnStmtsTable.vue';

export default {
  components: { BnStmtsTable },
};
</script>
