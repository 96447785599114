import { fetchData } from '../../services/fetch';

const base = process.env.VUE_APP_API_URL;

const getManagerList = async query => {
  try {
    const { ok, status, response } = await fetchData({
      url: `${process.env.VUE_APP_BANKON_API_URL}/managers?query=${query}`,
      headers: {
        'X-Authorization': `Bearer ${process.env.VUE_APP_BANKON_API_TOKEN}`,
      },
    });

    if (!ok) throw { status, response };

    return { ok: true, managers: response.data.managers };
  } catch (error) {
    return {
      ok: false,
      status: error?.status,
      response: error?.response,
      msg: 'Ошибка при сохранении изменений в заявке',
      error,
    };
  }
};

const checkAgent = async query => {
  try {
    const { ok, status, response } = await fetchData({
      url: `${process.env.VUE_APP_API_URL}/agents/profile?inn=${query}`,
    });

    if (!ok) throw { status, response };

    return { ok: true, data: response?.data };
  } catch (error) {
    return {
      ok: false,
      status: error?.status,
      response: error?.response,
      msg: 'Ошибка при проверке агента или агент не найден',
      error,
    };
  }
};

const checkClient = async query => {
  try {
    const { ok, status, response } = await fetchData({
      url: `${process.env.VUE_APP_BANKON_API_URL}/clients/profile?inn=${query}`,
      headers: {
        'X-Authorization': `Bearer ${process.env.VUE_APP_BANKON_API_TOKEN}`,
      },
    });

    if (!ok) throw { status, response };

    return { ok: true, data: response.data };
  } catch (error) {
    return {
      ok: false,
      status: error?.status,
      response: error?.response,
      msg: 'Ошибка при проверке агента или агент не найден',
      error,
    };
  }
};

/**
 * Сохранить изменения заявки
 * @param {Object} params - объект с параметрами и данными
 * @param {String} params.uuid
 * @param {Object} params.stmt
 * @param {Object} params.stmtParams
 */
const createStmt = async data => {
  try {
    const { ok, status, response } = await fetchData({
      url: `${base}/stmts/`,
      method: 'POST',
      parse: false,
      data: preparedRequestData(data),
    });

    if (!ok) throw { status, ...(response && { response }) };

    return { ok: true, response };
  } catch (error) {
    return {
      ok: false,
      status: error?.status,
      response: await error?.response?.json(),
      msg: 'Ошибка при сохранении изменений в заявке',
      error,
    };
  }
};

const preparedRequestData = data => {
  return {
    paid_dt: data.paid_dt.value,
    bank_uuid: Object.keys(data.bank.value || {})[0],
    product: {
      uuid: Object.keys(data.product.value || {})[0],
      type: Object.keys(data.product_type.value || {})[0],
    },
    law: Object.keys(data.law.value || {})[0],

    auction_number: data.auction_number.value,

    provision_sum: data.provision_sum.value,
    base_tariff: data.base_tariff.value,

    client: {
      uuid: data.client.uuid,
      name: data.client.value?.value,
      inn: data.client.value?.data?.inn,
      director: data.client.value?.data?.management?.name,
    },

    ...(data.agent.value && {
      agent: {
        uuid: data.agent.uuid,
        name: data.agent.value?.value,
        commission: data.agent_commission.value,
        inn: data.agent.value?.data?.inn,
        director: data.agent.value?.data?.management?.name,
      },
    }),

    ...(data.manager.value && {
      manager: {
        uuid: Object.keys(data.manager.value || {})[0],
        commission: data.manager_commission.value,
      },
    }),
  };
};

export { getManagerList, checkClient, checkAgent, createStmt };
