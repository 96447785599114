<template>
  <section class="pagination flex justify-between items-center with-full p-2">
    <div>
      <bn-button
        class="pagination__prev mr-2"
        :disabled="!(currentPage > 1)"
        @click="$emit('pageChanged', currentPage - 1)"
        >Назад
      </bn-button>

      <bn-button
        v-for="i in pages"
        :key="i"
        class="pagination__page mr-2"
        :class="{ active: i === currentPage }"
        square
        :disabled="i === currentPage"
        @click="$emit('pageChanged', i)"
        >{{ i }}
      </bn-button>
      <bn-button
        class="btn pagination__next mr-2"
        @click="$emit('pageChanged', currentPage + 1)"
        :disabled="!hasNextPage"
        >Вперед
      </bn-button>
    </div>
    <div class="flex items-center flex-auto px-2">
      <div class="pagination__status mr-2"></div>
      <div class="pagination__quantity-selection">
        <span>
          Показывать по:
          <select
            :value="itemsOnPage"
            @input="$emit('selectChanged', Number($event.target.value))"
          >
            <option v-for="v in itemsOnPageChoice" :key="v" :value="v">
              {{ v }}
            </option>
          </select>
        </span>
      </div>
    </div>
    <div class="pagination__status">
      <span>
        Элементов
        <b>{{ itemsOnPage > totalItems ? totalItems : itemsOnPage }}</b
        >/{{ totalItems }}
      </span>
      <span class="px-4">|</span>
      <span>
        Страница: <b>{{ currentPage }} </b>/{{ totalPages }}
      </span>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    currentPage: {
      type: Number,
      required: false,
    },
    totalPages: {
      type: Number,
      required: false,
    },
    itemsOnPage: {
      type: Number,
      required: false,
    },
    itemsOnPageChoice: {
      type: Array,
      required: false,
      default: () => [],
    },
    totalItems: {
      type: Number,
      required: false,
    },
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 11,
    },
  },
  computed: {
    position() {
      return this.currentPage - Math.floor(this.maxVisibleButtons / 2);
    },
    startPage() {
      /*const before = this.currentPage - 1; */
      const half = Math.floor(this.maxVisibleButtons / 2);
      const after = this.totalPages - this.currentPage;
      let page = this.currentPage - half;

      if (this.totalPages <= this.maxVisibleButtons) page = 1;

      if (after < half) page = this.totalPages - this.maxVisibleButtons + 1;

      return page > 0 ? page : 1;
    },
    pages() {
      const range = [];

      for (
        let i = this.startPage;
        i <=
        Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
        i++
      ) {
        range.push(i);
      }

      return range;
    },
    hasNextPage() {
      return this.totalPages > this.currentPage;
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  background-color: #fff;
  box-shadow: -1px -4px 5px -5px rgba(34, 60, 80, 0.6);

  .active {
    background-color: #4e4f61;
    opacity: 1;
    color: #fff;
  }
  select {
    padding: 5px;
  }
}
</style>
