/**
 * Логирует события и сохраняет в fullLog
 * и записывает последнее событие в параметр lastLog
 */
export default class Logger {
  DEBUG = false;
  fullLog = [];
  lastLog = '';
  prefix = '[DB]';
  tag = '';

  constructor() {
    if (typeof Logger.instance === 'object') return Logger.instance;

    Logger.instance = this;
    return this;
  }

  /**
   * Логирует события и сохраняет в fullLog
   * и записывает последнее событие в параметр lastLog
   * @param {(String|Object)} ожидает на вход строку или объект
   * @param {boolean} true если сообщение об ошибке, По умолчанию false
   *
   */
  add(data, error = false) {
    if (this.DEBUG) {
      error
        ? console.error(this.prefix, this.tag, data)
        : console.log(this.prefix, this.tag, data);
    }
    this.lastLog = data.msg ? data.msg : data;
    this.fullLog.push(data);
    this.tag = '';
  }
  addTag(newTag) {
    this.tag = newTag;
  }
}
//window.logger = new Logger();
