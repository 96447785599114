<template>
  <svg v-if="iconUrl" :style="{ width: size + 'px', height: size + 'px' }">
    <use :href="iconUrl"></use>
  </svg>
</template>

<script>
export default {
  props: {
    size: { type: [Number, String], required: false },
    icon: { type: String, required: false },
  },
  data() {
    return {
      iconUrl: null,
    };
  },
  created() {
    if (!this.icon) return;

    this.iconUrl = require(`@/assets/icons/${this.icon}.svg`).default.url;
  },
};
</script>

<style></style>
