<template>
  <div class="n-table">
    <div class="n-table__inner-wrapper">
      <div class="n-table__header-wrapper" ref="tableHeader">
        <table
          border="0"
          cellpadding="0"
          cellspacing="0"
          class="n-table__header"
          :width="`${
            (tableWidth > availableSpace
              ? tableWidth
              : Math.round(availableSpace)) + scrollWidth
          }px`"
        >
          <colgroup>
            <template v-for="(item, idx) in columns" :key="idx">
              <col
                v-if="item.show"
                :width="
                  columns.length - 1 === idx
                    ? recalculatedLastColWidth
                    : item.width
                "
              />
            </template>
            <col :width="scrollWidth" />
          </colgroup>
          <thead>
            <tr class="n-table__row">
              <bn-table-header-cell
                v-for="(col, idx) in columns"
                :key="idx"
                v-show="col.show"
                :column="col"
                :applied-filters="appliedFilters"
                :sorting="sortBy"
                @is-sorted="$emit('update:sortBy', $event)"
                @open-menu="$emit('open-menu', $event)"
              />
            </tr>
          </thead>
        </table>
      </div>
      <div @scroll="tableScroll" class="n-table__body-wrapper">
        <table
          border="0"
          cellpadding="0"
          cellspacing="0"
          :width="`${
            tableWidth > availableSpace
              ? tableWidth
              : Math.round(availableSpace)
          }px`"
        >
          <colgroup>
            <template v-for="(item, idx) in columns" :key="idx">
              <col
                v-if="item.show"
                :width="
                  columns.length - 1 === idx
                    ? recalculatedLastColWidth
                    : item.width
                "
              />
            </template>
          </colgroup>
          <tbody class="n-table__body">
            <tr
              v-for="(row, rowIdx) in rows"
              :key="rowIdx"
              class="n-table__row"
              :class="rowClassName({ row, rowIdx })"
              :data-row-idx="rowIdx"
            >
              <bn-table-body-cell
                v-for="(col, colIdx) in columns"
                :key="rowIdx + '-' + colIdx"
                @dblclick="
                  $emit('dbl-click-body-cell', {
                    e: $event,
                    col,
                    row,
                    rowIdx,
                  })
                "
                v-show="col.show"
                :column="col"
                :value="row[col.name]"
              >
                <slot
                  :name="`cell(${col.name})`"
                  :data="{ rowIdx, colName: col.name, row }"
                ></slot>
              </bn-table-body-cell>
              <slot :name="'row(end)'" :data="{ rowIdx, row }"></slot>
            </tr>
            <tr v-show="loading">
              <td colspan="100%">
                <div class="n-table__alert">
                  <div class="n-table__preloader">
                    <bn-preloader size="50" />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="isEmpty" class="flex justify-center items-center p-4 h-full">
          <div class="n-table__alert">
            Данных не найдено. Попробуйте изменить параметры запроса
          </div>
          <div v-if="error" class="n-table__alert">
            {{ error }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BnPreloader from '../ui/BnPreloader.vue';
import BnTableBodyCell from './BnTableBodyCell.vue';
import BnTableHeaderCell from './BnTableHeaderCell.vue';
import debounce from '../../helpers/debounce';

export default {
  components: {
    BnPreloader,
    BnTableHeaderCell,
    BnTableBodyCell,
  },
  props: {
    appliedFilters: { type: Array, required: false, default: () => [] },
    scrollWidth: { type: Number, required: false, default: 8 },
    rows: { type: Array, required: false, default: () => [] },
    rowClassName: { type: Function, required: false, default: () => '' },
    columns: { type: Array, default: () => [] },
    sortBy: { type: Object, required: false, default: () => {} },
    loading: { type: Boolean, default: false },
    error: { type: String, default: '' },
  },
  data() {
    return {
      availableSpace: null,
      recalculatedLastColWidth: null,
    };
  },
  computed: {
    isEmpty() {
      if (this.error) return false;

      return !this.loading && (!this.rows || this.rows.length === 0);
    },
    // Ширина таблицы рассчитанная на основе ширины столбцов из конфига таблицы
    tableWidth() {
      return this.columns.reduce((previous, current) => {
        if (!current.width || !current.show) return previous;

        return previous + current.width;
      }, 0);
    },
    lastColWidth() {
      return this.columns[this.columns.length - 1]?.width;
    },
  },
  watch: {
    availableSpace() {
      debounce(this.recalculatLastColWidth(), 250);
    },
  },
  mounted() {
    const debouncedResizeHandler = debounce(this.resizeHandler, 50);

    const observer = new ResizeObserver(debouncedResizeHandler);

    observer.observe(this.$refs.tableHeader);
  },
  methods: {
    recalculatLastColWidth() {
      const remainingSpace = this.availableSpace - this.tableWidth;

      this.recalculatedLastColWidth =
        remainingSpace > 0
          ? this.lastColWidth + remainingSpace
          : this.lastColWidth;
    },
    openContextMenu({ name, coordX, coordY }) {
      console.log(name, coordX, coordY);
    },
    resizeHandler(entries) {
      entries.forEach(entry => {
        this.availableSpace = entry.contentRect.width;
      });
    },
    tableScroll(e) {
      this.$refs.tableHeader.scrollLeft = e.target.scrollLeft;
    },
  },
};
</script>

<style lang="scss">
.n-table {
  width: 100%;
  height: 100%;

  &__inner-wrapper {
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &__header-wrapper {
    background-color: #eee;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.25);
    position: relative;
    z-index: 10;
    overflow: hidden;
  }
  &__header,
  &__body {
    border-collapse: separate;
    table-layout: fixed;
  }

  &__body-wrapper {
    width: 100%;
    overflow-y: auto;
    overflow-x: auto;
    flex: 1;
  }
  &__row:nth-child(even) td {
    background-color: #f6f6f6;
  }
  &__row:hover td {
    background-color: #ecf5ff;
  }
  &__cell {
    padding: 10px 0;
    position: relative;
    border-right: 1px solid #ebeef5;
    border-bottom: 1px solid #ebeef5;
    background-color: #fff;
    font-weight: 400;
    text-overflow: ellipsis;
    text-align: left;
    vertical-align: middle;

    .cell {
      padding-left: 10px;
      padding-right: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: break-spaces;
      word-break: normal;

      a {
        color: $color-text-primary;
      }
    }
  }
  &__cell:last-child {
    border-right: 1px solid #eee;
  }
}
</style>
