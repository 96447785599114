<template>
  <section class="av-page">
    <bn-table
      :rows="agents"
      :columns="tableHeaders"
      :loading="loading"
      :error="tableError"
    >
      <template #cell(status)="{ data: { row } }">
        <status-cell :row="row" />
      </template>

      <template #cell(agent_name)="{ data: { row } }">
        <router-link
          :to="{
            name: 'StmtsPage',
            query: {
              filter: 'agents',
              uuid: row.agent_uuid,
              value: row.agent_name,
            },
          }"
          target="_blank"
        >
          {{ row.agent_name }}
        </router-link>
      </template>

      <template #cell(av_sum)="{ data: { row } }">
        <template v-if="!row?.calculating_status?.status">
          {{ toCurrency(row.av_sum) }}
        </template>
        <template
          v-else
          :title="`Перерасчет начат: ${formattedDate(
            row?.calculating_status?.updated_at,
          )}  ${formattedTime(row?.calculating_status?.updated_at)}`"
        >
          <bn-preloader-for-button width="30px" />
        </template>
      </template>

      <template #cell(recalculate)="{ data: { row } }">
        <bn-button
          v-if="row.can.recalculate"
          color="info"
          :disabled="loading"
          @click="recalculate(row.agent_uuid)"
        >
          Пересчитать
        </bn-button>
      </template>

      <template #cell(sign_status)="{ data: { row } }">
        <div class="flex justify-center items-center">
          <sign-status-cell :row="row" />
        </div>
      </template>

      <template #cell(actions)="{ data: { row } }">
        <actions-cell
          :row="row"
          @sign-report="signReportHandler(row.agent_uuid)"
          @revoke="revoke(row.agent_uuid)"
          @download="download(row.agent_uuid)"
          @download-sign="downloadSign(row.agent_uuid)"
        />
      </template>

      <template #cell(chat)="{ data: { row } }">
        <bn-button
          v-if="currentChat.uuid !== row.agent_uuid"
          square
          icon="forum"
          @click="
            openChat({
              uuid: row.agent_uuid,
              name: row.agent_name,
            })
          "
          title="Открыть чат"
        />
        <bn-button
          v-else
          @click="closeChat"
          square
          icon="close"
          color="danger"
          title="Закрыть чат"
        />
      </template>
    </bn-table>

    <bn-av-table-right-panel
      :localized-current-date="localizedDate"
      :normalized-current-date="normalizedDate"
      :total="total"
    />

    <bn-chat-wrapper ref="chat">
      <template #main="{ close }">
        <bn-chat @close="close" :init="currentChat" :period="currentPeriod" />
      </template>
    </bn-chat-wrapper>
    <bn-modal :showFooter="false" :clickOutside="false" ref="selectCert">
      <template #default="{ close }">
        <bn-cert-list
          :list="certList"
          @cancel="close"
          @selected="certSelected"
        />
      </template>
    </bn-modal>
  </section>
</template>
<script>
import AvTableConfig from './av-table-config.json';
import BnTable from '../BnTable/BnTable.vue';
import StatusCell from './cells/BnAvTableStatusCell.vue';
import ActionsCell from './cells/BnAvTableActionsCell.vue';
import SignStatusCell from './cells/BnAvTableSignStatusCell.vue';
import BnPreloaderForButton from '../ui/BnPreloaderForButton.vue';
import BnAvTableRightPanel from './BnAvTableRightPanel.vue';
import BnCertList from '../BnCertList/BnCertList.vue';
import BnChatWrapper from '../BnChat/BnChatWrapper.vue';
import BnChat from '../BnChat/BnChat.vue';
import {
  formattedDate,
  formattedTime,
  toCurrency,
  normalizedDateFromObj,
  localizedDateFromObj,
} from '../../helpers/index';
import {
  getTableDataAv,
  recalculateAv,
  revokeReport,
  sendReport,
  uploadReport,
  downloadReport,
  downloadSignReport,
  signData,
  getReportInBase64,
  getCertificatesList,
} from './api-av';

export default {
  components: {
    BnTable,
    BnPreloaderForButton,
    StatusCell,
    SignStatusCell,
    ActionsCell,
    BnAvTableRightPanel,
    BnChatWrapper,
    BnChat,
    BnCertList,
  },

  ajax: null,
  abortController: null,

  data() {
    return {
      tableHeaders: AvTableConfig.data,
      agents: [],
      total: {
        stmts: null,
        agents: null,
        approvedAgents: null,
        provisionSum: null,
        finalTariff: null,
        avSum: null,
      },
      currentChat: {
        name: null,
        uuid: null,
      },
      currentPeriod: {
        month: null,
        year: null,
      },

      settings: {
        isCoSign: false,
        isDetached: false,
      },
      certList: [],
      selectedCert: null,

      loading: false,
      isSigning: false,

      tableError: null,
    };
  },
  async mounted() {
    if (this.isLoggedIn) await this.getTableData();
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
    period() {
      return this.$store.state.filter.period;
    },
    localizedDate() {
      return localizedDateFromObj(this.currentPeriod);
    },
    normalizedDate() {
      return normalizedDateFromObj(this.currentPeriod);
    },
  },
  methods: {
    async signReportHandler(uuid) {
      this.isSigning = true;

      /* this.$store.dispatch('ADD_ALERT', {
        type: 'success',
        text: 'Получаем отчёт для подписания',
      }); */

      const { ok, report } = await getReportInBase64({
        uuid,
        ...this.currentPeriod,
      });

      if (!ok) {
        this.$store.dispatch('ADD_ALERT', {
          text: 'Ошибка при получении отчета на подпись',
          type: 'error',
        });
        this.isSigning = false;
        return;
      }

      /*  this.$store.dispatch('ADD_ALERT', {
        text: 'Отчёт успешно получен',
        type: 'success',
      }); */

      const cert = await this.selectСertificate();

      if (!cert) {
        this.isSigning = false;
        return;
      }

      const dataToSign = {
        file: {
          content: report,
        },
        isCoSign: this.settings.isCoSign,
        isDetached: this.settings.isDetached,
      };

      const signedData = await signData({ cert, dataToSign });

      if (!signedData.ok) {
        this.$store.dispatch('ADD_ALERT', {
          text: 'Ошибка при подписании отчета',
          type: 'error',
        });
        this.isSigning = false;
        return;
      }

      this.$store.dispatch('ADD_ALERT', {
        text: 'Отчёт успешно подписан',
        type: 'success',
      });

      await this.uploadSignedReport({ uuid, data: signedData.data });

      this.isSigning = false;
    },
    certSelected(thumbprint) {
      this.selectedCert = thumbprint;
      this.$refs.selectCert.confirm();
    },
    async selectСertificate() {
      const { ok, certificates } = await getCertificatesList();

      if (!ok) {
        this.$store.dispatch('ADD_ALERT', {
          text: 'Ошибка при получении списка сертификатов',
          type: 'error',
        });
        return false;
      }

      this.certList = certificates;

      const confirm = await this.$refs.selectCert.open();

      if (!confirm) return false;

      const cert = this.certList.find(
        item => item.thumbprint === this.selectedCert,
      );

      return cert;
    },
    async uploadSignedReport({ uuid, data }) {
      const hash = data.file.sign.content;
      const { ok, report } = await uploadReport({
        hash,
        uuid,
        type: 'signed-report',
        ...this.currentPeriod,
      });

      if (!ok) {
        this.$store.dispatch('ADD_ALERT', {
          text: 'Ошибка при отправке подписанного отчёта',
          type: 'error',
        });
        return;
      }

      const reportIdx = this.agents.findIndex(
        agent => agent.agent_uuid === uuid,
      );

      this.agents.splice(reportIdx, 1, report);

      this.$store.dispatch('ADD_ALERT', {
        text: 'Отчёт успешно отправлен',
        type: 'success',
      });

      this.clearSigning();
    },
    clearSigning() {
      this.certList = [];
      this.selectedCert = null;
    },
    async openChat(init) {
      this.currentChat = init;
      const chatIsOpen = await this.$refs.chat.open();

      if (!chatIsOpen) this.currentChat = { name: null, uuid: null };
    },
    closeChat() {
      this.$refs.chat.close();
    },
    async send(uuid) {
      this.loading = true;
      const result = await sendReport({ uuid, ...this.currentPeriod });

      this.agents.forEach(agent => {
        if (agent.agent_uuid === result.agent_uuid)
          Object.assign(agent, result);
      });

      this.loading = false;
    },
    async revoke(uuid) {
      this.loading = true;
      const result = await revokeReport({ uuid, ...this.currentPeriod });

      this.agents.forEach(agent => {
        if (agent.agent_uuid === result.agent_uuid)
          Object.assign(agent, result);
      });
      this.loading = false;
    },
    async download(uuid) {
      this.loading = true;

      const { ok } = await downloadReport({ uuid, ...this.currentPeriod });

      if (!ok) {
        this.$store.dispatch('ADD_ALERT', {
          type: 'error',
          text: 'Ошибка при скачивании файла',
        });
      }

      this.loading = false;
    },
    async downloadSign(uuid) {
      this.loading = true;

      const { ok } = await downloadSignReport({ uuid, ...this.currentPeriod });

      if (!ok) {
        this.$store.dispatch('ADD_ALERT', {
          type: 'error',
          text: 'Ошибка при скачивании файла',
        });
      }

      this.loading = false;
    },
    async recalculate(uuid) {
      this.loading = true;
      const { ok, data } = await recalculateAv({
        uuid,
        period: this.period,
      });

      this.loading = false;
      if (!ok) {
        this.$store.dispatch('ADD_ALERT', {
          type: 'error',
          text: 'Ошибка при отправке запроса на перерасчёт',
        });
        return;
      }

      this.agents.forEach(agent => {
        if (agent.agent_uuid === data.agent_uuid) Object.assign(agent, data);
      });
      this.$store.dispatch('ADD_ALERT', {
        text: `Агент ${this.getAgentNameByUuid(
          data.agent_uuid,
        )} - начат перерасчёт`,
        type: 'success',
      });
    },
    getAgentNameByUuid(uuid) {
      const currentAgent = this.agents.find(el => el.agent_uuid === uuid);

      return currentAgent.agent_name;
    },
    async getTableData() {
      this.loading = true;
      const { ok, data } = await getTableDataAv(this.period);
      this.loading = false;

      if (!ok) {
        this.$store.dispatch('ADD_ALERT', {
          text: 'Ошибка при получении данных',
          type: 'error',
        });
        return;
      }
      this.currentPeriod = {
        month: data.month,
        year: data.year,
      };

      this.total.stmts = data.total_stmts;
      this.total.agents = data.total_agents;
      this.total.approvedAgents = data.total_approved_agents;
      this.total.provisionSum = data.provision_sum;
      this.total.finalTariff = data.final_tariff;
      this.total.avSum = data.av_sum;

      this.agents = data.agents;
    },
    toCurrency,
    formattedDate,
    formattedTime,
  },
  watch: {
    isLoggedIn(isLogged) {
      if (!isLogged) return;

      this.getTableData();
    },
    period() {
      this.getTableData();
    },
  },
};
</script>
<style lang="scss">
.av-page {
  position: fixed;
  top: 60px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  background-color: #fff;
  font-size: 0.8rem;

  .n-table {
    flex: 1;
    width: calc(100% - 300px);

    th .cell,
    td .cell {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    [data-column-name='status'] .cell,
    [data-column-name='total_stmts'] .cell,
    [data-column-name='recalculate'] .cell,
    [data-column-name='sign_status'] .cell,
    th[data-column-name='actions'] .cell {
      justify-content: center;
    }

    th[data-name='chat'] .cell {
      padding-left: 15px;
    }

    td[data-column-name='actions'] .cell {
      justify-content: flex-end;
      overflow: visible;
    }

    td[data-column-name='actions'] .cell > div > .bn-button--success,
    td[data-column-name='actions'] .cell > div > .bn-button--danger {
      min-width: 114px;
    }
  }

  [data-column-name='status'] .cell {
    padding-left: 10px;
    padding-right: 10px;
  }
  .bn-table__right-panel {
    width: 300px;
    padding: 30px;
    position: relative;
    z-index: 50;
    box-shadow: $shadow-light;
  }
}
</style>
