import { fetchData } from '../../services/fetch';

const base = process.env.VUE_APP_API_URL;

/* Чат */

/**
 * Получить сообщения
 * @param {Object} { id, period } - параметры запрашиваемых сообщений
 */
const getMessages = async ({ id, period }) => {
  try {
    const { ok, response } = await fetchData({
      url: `${base}/av/${id}/reports/${period.year}/${period.month}/chat/`,
    });

    if (!ok) throw 'Ошибка при получении списка сообщений';

    return {
      ok: true,
      messages: response.data.messages,
      totalMessages: response.data.total_messages,
      newMessages: response.data.total_new_messages,
    };
  } catch (error) {
    console.error(error);
    return { ok: false, error: error };
  }
};

/**
 * Отправить сообщение
 * @param {String} period - выбранный период
 */
const sendMessage = async ({ id, period, data }) => {
  try {
    const { ok, response } = await fetchData({
      url: `${base}/av/${id}/reports/${period.year}/${period.month}/chat/`,
      method: 'POST',
      data,
    });

    if (!ok) throw 'Ошибка при отправке сообщения';

    return {
      ok: true,
      message: response.data,
    };
  } catch (error) {
    console.error(error);
    return { ok: false, error: error };
  }
};
/**
 * Редактировать сообщение
 * @param {String} period - выбранный период
 */
const editMessage = async ({ id, period, message }) => {
  try {
    const { ok, response } = await fetchData({
      url: `${base}/av/${id}/reports/${period.year}/${period.month}/chat/${message.uuid}/update`,
      method: 'POST',
      data: message,
    });

    if (!ok) throw 'Ошибка при редактировании сообщения';

    return {
      ok: true,
      message: response.data,
    };
  } catch (error) {
    console.error(error);
    return { ok: false, error: error };
  }
};
/**
 * Удалить сообщение
 * @param {String} period - выбранный период
 */
const deleteMessage = async ({ id, period, uuid }) => {
  try {
    const { ok } = await fetchData({
      url: `${base}/av/${id}/reports/${period.year}/${period.month}/chat/${uuid}`,
      method: 'DELETE',
    });

    if (!ok) throw 'Ошибка при удалении сообщения';

    return {
      ok: true,
    };
  } catch (error) {
    console.error(error);
    return { ok: false, error: error };
  }
};
/**
 * Скачать прикрепленный файл
 * @param {Object} { id, period, messageId, attachmentId } - параметры файла
 */
const downloadAtachment = async ({
  id,
  period,
  messageId,
  attachmentId,
  attachmentName,
}) => {
  try {
    const { ok, response } = await fetchData({
      url: `${base}/av/${id}/reports/${period.year}/${period.month}/chat/${messageId}/${attachmentId}`,
      parse: false,
    });

    if (!ok) throw 'Ошибка при скачивании прикрепленного файла';

    const blob = await response.blob();
    const file = new File([blob], attachmentName);
    const link = document.createElement('a');

    link.download = file.name;
    link.href = URL.createObjectURL(file);
    link.click();
    URL.revokeObjectURL(link.href);

    return { ok: true };
  } catch (error) {
    console.error(error);
    return { ok: false, error: error };
  }
};

export {
  getMessages,
  sendMessage,
  editMessage,
  deleteMessage,
  downloadAtachment,
};
